import { isRef, } from 'vue';
import i18n  from '../i18n';
import moment from 'moment';
import globalFunctions from '@/utils/globalFunctions';
import { computed } from 'vue';
import { changeUserLanguage  } from '@/api/settings.api';
import languageHelperConstants from './languageHelpers.constants.js';
import { useAppStore } from '@/store/app.store';
import { useAuthStore } from '@/store/auth.store';

const {
    LANGUAGES,
    LANG_LOCALES,
    LANG_LOCALES_ARRAY,
    DEFAULT_LOCALE_KEY,
    DEFAULT_LOCALE,
    DEFAULT_LANGUAGE,
    FALLBACK_LOCALE,
    FALLBACK_LANGUAGE,
    VIDEO_LOCALES,
} = languageHelperConstants;

const currentAllowedLocalesRef = computed(() => {
    const appStore = useAppStore();
    
    return appStore.supportedLangs.length > 0
            ? appStore.supportedLangs
            : LANG_LOCALES_ARRAY;
});

const currentLang = computed(()=> getGenericLocale(i18n.global.locale.value));

function checkSpecialText(companyId, key) {

    if (companyId.includes('_')) {
        companyId = companyId.substring(0, companyId.indexOf('_'));
    }

    return i18n.global.te(`${companyId}.${key}`)
        ? i18n.global.t(`${companyId}.${key}`)
        : i18n.global.t(key);
}

// settings lang list - all supported in FE
// settings lang list for this company
// user current lang_locale  === FE locale
// vuetify current lang_locale
// html current lang_locale
// moment current lang_locale
function setLanguageLocale(newLocale/*, noFallback*/) {
    const locale = i18n.global.locale;
    l('original locale:', locale.value);

    if (!newLocale) {
        newLocale = locale.value;
    }

    if (newLocale?.toLowerCase() === 'deutsch') {
        newLocale = LANG_LOCALES.DE_CH;
    }

    newLocale = getSpecificLocale(newLocale);

    const appStore = useAppStore();
    const authStore = useAuthStore();

    const loggedIn = authStore.status.loggedIn;
    const userSettings = appStore.userSettings;

    if (loggedIn && userSettings) {
        newLocale = userSettings.languages.includes(newLocale)
                ? newLocale
                : userSettings.languages[0];
    }

    if (newLocale && locale.value != newLocale) {
        locale.value = newLocale;
    }

    l('new locale:', locale.value);


    if (newLocale && document.documentElement.lang != newLocale) {
        document.documentElement.lang = newLocale;
    }

    if (newLocale) {
        moment.locale(newLocale);
    }

    try {
        localStorage.setItem(DEFAULT_LOCALE_KEY, newLocale);
    } catch {}
}

function changeLanguage(locale) {
    const appStore = useAppStore();
    const authStore = useAuthStore();

    setLanguageLocale(locale);

    if (authStore.status.loggedIn) {

        return changeUserLanguage(locale).then(() => {
            globalFunctions.piwikAnalytics([
                'trackEvent',
                'User language changed',
                locale,
            ]);
            appStore.changeUserLang(locale);
            
            window.l('user language changed to ' + locale);
        });
    } else {
        window.l('no user loggedIn - cant change user language');
    }
}

const tagTranslations = {
    'alter': 'age',
    'age': 'age',
    'âge': 'age',
    'età': 'age',
    'gender': 'gender',
    'geschlecht': 'gender',
    'le genre': 'gender',
    'genre': 'gender',
    'job': 'profession',
    'arbeit': 'profession',
    'travail': 'profession',
    'lavoro': 'profession',
    'profession': 'profession',
    'beruf': 'profession',
    'professione': 'profession',
    'office': 'office',
    'büro': 'office',
    'bureau': 'office',
    'ufficio': 'office',
    'location':'location',
    'lage':'location',
    'emplacement':'location',
    'posizione':'location',
    'standort': 'location',
    'ort': 'location'
}

// returns i18n translation string for tags if possible
function tagTranslationKey(tag) {
    if (tag && typeof tag === 'string') {
       let tags = Object.keys(tagTranslations);
       let tagTxt;

        if (tags.includes((tagTxt = tag.toLowerCase()))) {
            return tagTranslations[tagTxt];
        } else {
            return false;
        }
    } else {
        return false;
    }
};

function getSpecificLocale(langCodeISO639) {
    if (!langCodeISO639) {
        le('locale had no value, locale ===', langCodeISO639);

        return '';
    }

    return langCodeISO639 === LANGUAGES.EN
        ? LANG_LOCALES.EN_GB
        : langCodeISO639 === LANGUAGES.DE
            ? LANG_LOCALES.DE_CH
            : langCodeISO639;
};

function getGenericLocale(locale) {
    if (isRef(locale)) {
        le('getGenericLocale:, locale is passed in as a ref - use its `.value` instead');
    }

    if (!locale || (typeof locale !== 'string')) return '';

    return locale.split('-')[0];
};

export {
    DEFAULT_LANGUAGE,
    DEFAULT_LOCALE,
    FALLBACK_LANGUAGE,
    FALLBACK_LOCALE,
    LANGUAGES,
    LANG_LOCALES,
    VIDEO_LOCALES,

    changeLanguage,
    checkSpecialText,
    currentAllowedLocalesRef,
    currentLang,
    getGenericLocale,
    getSpecificLocale,
    setLanguageLocale,
    tagTranslationKey
}
