<template>
    <v-container class="fill-height login-success" style="position: relative">
        <v-row justify="center" align="center" v-if="showGreeting">
            <v-col cols="12" sm="10" md="8" lg="7" xl="6">
                <v-card class="elevation-12">
                    <v-card-text class="pt-6 px-12 pb-12">

                        <div class="mt-12 text-h4 text-center">
                            {{ $t("hi") }}
                            <span v-if="currentUserName">, {{ currentUserName }}</span>!
                        </div>
        
                        <v-img 
                            class="pulse quick-fade-in d-flex mx-auto" 
                            src="/images/surveyIcons/coffee/5.png" 
                            width="200">
                        </v-img>
        
                        <section class="mt-12">
                            <h3 class="text-h6 text-center text-primary text-capitalize">
                                {{ $t('login.selectYourCompany') }}
                            </h3>

                            <v-select 
                                v-if="memberships && memberships.length > 1" 
                                class="mt-6"
                                v-model="selectedMembership" 
                                :items="memberships"
                                item-title="companyName" 
                                item-value="membershipId" 
                                :label="$t('select')"
                                variant="solo"
                                @update:model-value="onMembershipChange"
                            >
                            </v-select>
                        </section>
        
        
                        <v-progress-circular 
                            v-if="!selectedMembership && (!memberships || memberships.length <= 1)"
                            class="d-flex mx-auto" indeterminate color="primary"
                        >
                        </v-progress-circular>
                    </v-card-text>
                </v-card>
            </v-col>
        </v-row>
    </v-container>
</template>

<script setup>
    import { getSurveys } from "@/api/takeSurvey";
    import { ref, computed, onMounted, inject, onUnmounted } from "vue";
    import { useRouter, useRoute } from "vue-router";
    import { useI18n } from "vue-i18n";
    import { selectProfile } from "@/api/auth.api";
    import { useAuthStore } from "@/store/auth.store";
    import { useAppStore } from "@/store/app.store";

    const targetPath = ref(null);
    const selectedMembership = ref(null);
    const router = useRouter();
    const route = useRoute();
    const { locale } = useI18n();
    const authStore = useAuthStore();
    const appStore = useAppStore();

    const memberships = computed(() => authStore.memberships);
    const showGreeting = computed(() => authStore.showGreeting);
    const currentUserName = computed(() => authStore.currentUserName);

    onMounted(() => {
        authStore.loadProfiles().then(() => {
            if (!authStore.hasMultipleMemberships) {
                tokenLogin();
            }
        });

        if (route?.query?.target) {
            l("[LoginSuccess] existing target path: " + route.query.target);

            targetPath.value = route.query.target;
        }
    });

    const targetSuffix = computed(() => {
        return targetPath.value ? `?target=${targetPath.value}` : "";
    })

    const tokenLogin = () => {
        l("in tokenLogin");

        authStore.tokenLogin()
            .then(handleLoginResponse)
            .catch(error => {
                le("[LoginFail]: tokenLogin not successful, routing back to /login");
                le(error);
                // return authStore.logout();
            });
    }

    const handleLoginResponse = async (user) => {
        l("in handleLoginResponse");
        // language can be null, hence fallback check via settings below as well
        if (user?.language) {
            locale.value = user.language;
        } else {
            l("[LoginSuccess]: tokenLogin, user?.language:", user?.language);
        }

        // set settings in store
        const result = await appStore.setSettings(user);
        return setNextPath(result);
    }

    const setNextPath = () => {
        l("in setNextPath");

        let userSetPromise = new Promise((success, error) => {
            const needsUserAsyncAccess = authStore.user === null;
            l('in userSetPromise, needsUserAsyncAccess:', needsUserAsyncAccess);

            if (!needsUserAsyncAccess) {
                l('in userSetPromise, had user');
                success(true);
            } else {
                l('in userSetPromise, NO user yet');

                appStore.on('localStorage:setItem:user', (value) => {
                    l('in userSetPromise, eventHandler; value:', value);

                    if (value) {
                        success(value);
                    }
                });
            }

        })

        return Promise.allSettled([getSurveys(), userSetPromise])
            .then(([response, userSet]) => {
                if (response.status !== 'fulfilled') {
                    throw response.reason;
                }

                if (userSet.status !== 'fulfilled') {
                    throw userSet.reason;
                }

                let activeSurveyFlag = response.value.data.length > 0;
                // let nextPath;

                if (activeSurveyFlag) {
                    window.sessionStorage.setItem('hasActiveSurvey', 'true');
                }

                const nextPath = authStore.getNextPathForUser(targetPath.value);

                return goNext(nextPath);
            })
            .catch(error => {
                le("[LoginStatus]: can't retrieve surveys to calculate next path, falling back to TB");
                le(error);

                return goNext("/tb");
            });
    }

    const goNext = (path) => {
        authStore.showGreeting = false;
        return router.push(path);
    }
    
    const onMembershipChange = () => {
        if (selectedMembership.value) {
            l("selectedMembership changed to:", selectedMembership.value);
            selectProfile(selectedMembership.value).then(tokenLogin);
        }
    };

</script>
