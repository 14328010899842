import { CALIDA_GROUP_COMPANY_IDS, POST_GROUP_COMPANY_IDS, TX_GROUP_COMPANY_IDS } from "../customisations/constants";
import { assignToObjectKeys, simpleDeepMerge } from "../utils/object";

export default Object.assign(commonTranslations(), simpleDeepMerge(commonCustomisations(), customisations()));

function commonTranslations() {
    return {
        joineerContact: {
            salesEmail: "info{'@'}joineer.ch",
            salesPhone: "+41\u00A044\u00A0510\u00A038\u00A000",
        },
        addLinks:"Ajout de liens?",
        whatYouwrite: "Ce que tu écris",
        whatIsShown:"Ce qui est montré",
        anonymValues:"Les valeurs numériques sont toujours saisies anonymement",
        pauseSurvey: "Pause enquête",
        surveyPaused: "Enquête en pause",
        events: {
            start: {
                title: "L'enquête commence",
                description: "Des courriels/SMS avec l'invitation à participer sont envoyés à tous les membres",
            },
            reminder: {
                title: "Rappel",
                description: "Courriel/SMS rappelant les participants qui n'ont pas encore rempli le formulaire",
            },
            end: {
                title: "Fin de l'enquête",
                description: "Dernier jour de remplissage. Les résultats seront disponibles le lendemain",
            },
            today: "Aujourd'hui",
            endSurveyNow: "Terminer l'enquête",
            confirmation: "Confirmation",
            endSurveyConfirmationMsg: "Es-tu sûr(e) de vouloir terminer cette enquête ? \n Cela NE PEUT PAS être annulé.",
            endSurveyScheduled:  "Enquête terminée, veuillez accorder au système 15 à 30 minutes pour le traitement.",
        },
        nextDay: "le jour ouvrable suivant",
        changeTeam: "Équipe de changement",
        pickNewTeam:
            "Choisissez une nouvelle {0} équipe dans l'organigramme ci-dessous.",
        currentTeam: "Équipe actuelle",
        activeSurveyMoveWarning:
            "Le déplacement d'un membre pendant une enquête active supprime ses réponses actuelles. Après le déplacement, il sera nécessaire de remplir à nouveau l'enquête pour la nouvelle équipe.",

        startDateNextSurvey: "Date de début de l'enquête suivante",
        interval: {
            label: "Intervalle d'enquête",
            text: "À quelle fréquence souhaitez-vous que l'enquête soit réalisée?",
            everyw: "Chaque semaine",
            every2w: "Toutes les 2 semaines",
            every3w: "Toutes les 3 semaines",
            monthly: "Chaque mois",
            every6w: "Toutes les 6 semaines",
            every2m: "Tous les 2 mois",
            every3m: "Tous les 3 mois",
            every4m: "Tous les 4 mois",
            none: "Manuel"
        },
        rolesTitle: "Rôles",
        roleTitle: "Rôle",
        adminOfTeams: "Admin de",
        addCompanyAdmin: "Ajouter un administrateur d'entreprise",
        tags: "Tags",
        masterfile: "Masterfile de l'entreprise",
        masterfileText:
            "Conseils utiles et outil de validation pour le Masterfile: ",
        processdatafile: "Données de Processus",
        processdatafileDescription:
            "Tu obtiens ici des données de processus intéressantes pour ton organisation. De plus, tu peux suivre l'évolution des données sur plusieurs enquêtes.",
        processDataMoreInfo: "Plus de Info",
        processDataInfo: {
            title: {
                survey: "Survey",
                surveyEndDate: "Survey End Date",
                avgQuestionPerTeam: "Average Questions per Team",
                participants: "Participants",
                respondents: "Respondents",
                participationRate: "Participation Rate (%)",
                totalFF: "Total FFs",
                transparencyRate: "Transparency Rate (%)",
                FFPerRespondents: "FFs per Respondent per Question",
                handlingRate: "FF Handling Rate (%)",
                tasksCreated: "Tasks Created",
                closingRate: "Closing Rate (%)",
                delegationRate: "Delegation Rate (%)",
            },
            desc: {
                survey: "Ordre croissant.",
                surveyEndDate: "La date à laquelle chaque sondage a été terminé.",
                avgQuestionPerTeam: "Le nombre moyen de questions posées à chaque équipe dans l'enquête.",
                participants: "Le nombre total de personnes invitées à participer à l'enquête.",
                respondents: "Le nombre de personnes qui ont effectivement participé à l'enquête.",
                participationRate: "Le pourcentage de personnes qui ont terminé l'enquête par rapport au nombre de personnes invitées.",
                totalFF: "Le nombre total de feedforwards (FFs) reçus dans l'enquête.",
                transparencyRate: "Le pourcentage de FFs non anonymes par rapport au nombre total de FFs.",
                FFPerRespondents: "Le nombre moyen de FFs donnés par une personne ayant participé à l'enquête pour chaque question.",
                handlingRate: "Le pourcentage de FF marquées 'discuté' ou à partir desquelles une tâche a été créée.",
                tasksCreated: "Le nombre total de tâches qui ont été créées, y compris les tâches qui ne sont pas directement dérivées des FF.",
                closingRate: "Le pourcentage de tâches marquées comme 'terminées' par rapport au nombre total de tâches créées.",
                delegationRate: "Le pourcentage de tâches déléguées, par rapport au nombre total de tâches créées.",
            },
        },

        profile: {
            name: "Nom du profil",
            details: "Détails du profil",
            activatePassword: "Activer le mot de passe",
            passwordTooltip: "Active la connexion par mot de passe et désactive la connexion par lien e-mail. Ceci ne peut pas être annulé.",
            passwordActiveTooltip: "Le mot de passe est actif",
            externalAccount: "Compte externe",
        },

        account: {
            myaccount: "Mon Compte",
            pendingEmail: "E-mail en attente"
        },

        myProfile: "Mon profil",
        teamDetails: "Détails de l'équipe",
        selectUser: "Sélectionnez un membre",
        selectUserQR:
            "Sélectionnez un membre pour télécharger son code QR au format PDF.",
        pleaseEnterOneFollowing:
            "Veuillez saisir au moins un des éléments suivants:",
        errorCreatingUser: "Erreur lors de la création d'un membre",
        userTeamMoveWarning:
            "Ce membre est actuellement dans une autre équipe. L'ajout de {0} à {1} le supprime de {2}.",
        userTeamMoveConfirm:
            "Pour confirmer le déplacement, cliquez à nouveau sur 'Sauvegarder'.",
        selectExistingUser: "Sélectionnez un membre existant",
        addNew: "Créer un nouveau",
        addExisting: "Ajoutez un membre existant",
        createMember: "Créer un nouveau membre",
        createAdmin: "Créer un nouvel admin",
        dialCode: "Composez le code",
        loadingProfile: "Profil de chargement",
        firstName: "Prénom",
        lastName: "Nom de famille",
        updateName: "Mise à jour du nom",
        updateEmail: "Mise à jour de l'adresse e-mail",
        updateMobile: "Mise à jour du téléphone mobile",
        errorMobile: "Erreur de mise à jour du numéro de mobile",
        errorEmail: "Erreur de mise à jour de l'adresse e-mail",
        enterMobileText:
            "Le numéro de téléphone mobile doit être saisi avec un code d'accès, par exemple 4178322222.",
        updateLanguage: "Mise à jour de la langue",
        deleteProfileConfirmation:
            "Êtes-vous sûr de vouloir supprimer ce profil de l'organisation?",
        profileDeleted: "Ce profil est maintenant supprimé",
        directingToPrevious: "Renvoi à la page précédente",
        deleteProfile: "Supprimer le profil",
        noDeleteEmailError:
            "Impossible de supprimer l'adresse électronique car il faut enregistrer au moins une méthode de contact. Veuillez ajouter un numéro de téléphone portable au profil afin de pouvoir supprimer l'adresse électronique.",
        noDeleteMobileError:
            "Impossible de supprimer le numéro de téléphone mobile car il faut enregistrer au moins une méthode de contact. Veuillez ajouter un e-mail au profil afin de pouvoir supprimer le numéro de téléphone mobile.",
        entitiesAdministratedBy: "Équipes et structures administrées par {0}",
        someGuides: "Voici quelques guides pour vous!",
        "20people": "+20 personnes",
        addEntityToCompany: "Ajouter à l'entreprise",
        detailsPage: "Page de détails",
        createEntityText1:
            "Afin de créer une nouvelle structure {0} ou une équipe {1}, cliquez sous l'endroit où elle doit aller.",
        createEntityLvl0Text1:
            "Afin de créer une nouvelle équipe {0}, cliquez sur l'entreprise {1}.",
        createEntityText2:
            "Une fois créé, vous pouvez aller sur leur page de détails pour ajouter des admins/membres ou continuer à créer d'autres équipes/structures.",
        createEntityQuestion: "Que voulez-vous créer?",
        nameofNewTeam: "Nom de la nouvelle équipe",
        nameofNewStructure: "Nom de la nouvelle structure",
        openAll: "Tout ouvrir",
        closeAll: "Tout fermer",
        mandatoryLangTooltip:
            "La langue obligatoire est déterminée par l'organisation",
        noActiveSurveys: "Aucune enquête active pour le moment",
        teamAdmins: "Admins de l'équipe",
        structureAdmins: "Admins de la structure",
        teamMove: "Déplacer l'équipe",
        structureMove: "Déplacer la structure",
        moveText:
            "Cliquez sur la structure sous laquelle {0} doit se trouver. Les options non disponibles sont grisées.",
        structureLabel: "Structure",
        moveHere: "Déplacez-vous ici",
        currentLocation: "Localisation actuelle",
        teamRename: "Renommer l'équipe",
        teamDelete: "Supprimer l'équipe",
        structureRename: "Renommer la structure",
        structureDelete: "Supprimer la structure",
        deleteEntityConfirmation: "Etes-vous sûr de vouloir supprimer {0}?",
        structureDeleteInfo:
            "NB: Les équipes et structures sous {0} seront également supprimées. Les membres des équipes supprimées ne participeront pas aux enquêtes de pouls et seront listés sur la page 'Membres' sans équipe attribuée.",
        teamDeleteInfo:
            "NB: Après suppression, les membres de cette équipe seront listés sur la page 'Membres' sans équipe attribuée. Les membres sans équipe ne participent pas aux enquêtes de pouls.",
        newName: "Nouveau nom",
        cantMoveTopStructure: "Impossible de déplacer une structure de haut niveau",
        memberRemove: "Retirer le membre",
        adminRemove: "Retirer l'admin",
        removeInfo:
            "Une fois retirés, les membres sans équipe peuvent être retrouvés et réaffectés sur la page 'Membres'.",
        adminRemoveConfirmation:
            "Etes-vous sûr de vouloir supprimer {0} comme admin de {1}?",
        memberRemoveConfirmation:
            "Etes-vous sûr de vouloir retirer {0} comme membre de {1}?",
        tfaCountry: "Pays",
        tfaInvalid: "Numéro non valide",
        tfaRemove0: "Veuillez retirer 0",
        tfaRequired: "Le numéro est requis",
        labelMobilePhone: "Le téléphone mobile",
        twoFactorTitle: "Authentification à deux facteurs",
        twoFactorIntro:
            "Afin de visualiser les résultats une fois que le sondage est terminé, nous te demandons d'activer l'authentification à deux facteurs. Entre ton numéro de téléphone où les codes d'authentification pourraient être envoyés à l'avenir. En cas contraire, il ne sera pas possible d'accéder aux résultats.",
        twoFactorNoNr:
            "Je ne veux pas donner mon numéro ni avoir accès aux résultats.",
        twoFactorNoNrExplanation:
            "Sans fournir ton numéro, tu ne pourras pas accéder au tableau de bord des résultats après le sondage. Ce numéro ne sera utilisé qu'à des fins d'authentification. En cas de refus de fournir le numéro, il est possible de le communiquer ultérieurement à ton superviseur afin d'activer l'authentification à deux facteurs pour toi.",
        twoFactorFirst:
            "Ces informations sont accessibles après avoir entré le code envoyé à ton téléphone mobile.",
        emptyResult: "résultat vide",
        noTeamsFoundWith: "Aucun nom d'équipe trouvé avec {0}",
        removeFromTeam: "Supprimer le membre",
        removeAsAdmin: "Supprimer l'administrateur",
        noAdmins: "Aucun administrateur",
        noMembers: "Aucun membre",
        seeDetails: "See details",
        loggingOut: "Déconnexion",
        calculate: "Calculer",
        calculateENPSavgTitle: "Calculer la moyenne des ENPS",
        calculateENPSavgLabel: "ENPS moyen de:",
        calculateENPSavgSelect: "Sélectionnez les entités pour calculer l'ENPS moyen:",
        pwProtectedMessage:
            "Le compte est protégé par un mot de passe. Veuillez vous connecter avec votre mot de passe.",
        passwordError: "Er erreur dans la configuration du mot clé",
        passwordLengthError: "Le mot de passe doit comporter au moins 7 caractères",
        passwordMismatch:
            "Le mot de passe ne correspond pas. Réessaye, s'il vous plaît!",
        passwordChangeSuccess:
            "Mot de passe modifié avec succès. Connectez-vous {0}.",
        labelNewPassword: "Nouveau mot de passe",
        labelRepeatNewPassword: "Répéter le nouveau mot de passe",
        setNewPassword:
            "S'il vous plaît, définis un nouveau mot de passe d'au moins 7 caractères.",
        getNewPwLink:
            "Obtenez un nouveau lien de réinitialisation du mot de passe {0}",
        resetPw: "Réinitialiser le mot de passe",
        resetPwTokenInvalid: "Lien de réinitialisation du mot de passe invalide",
        forgotPwTitle: "Mot de passe oublié?",
        forgotPwText:
            "Nous t'enverrons un e-mail contenant un lien pour réinitialiser ton mot de passe.",
        fillingOutForTeam: "Tu remplis pour {0}.",
        orContinueWith: "continuer avec",
        level: "Niveau",
        showTodo: "Afficher ma liste de tâches",
        hideTodo: "Cacher ma liste de tâches",
        statistics: "Statistiques",
        commentTooLong:
            "La longueur maximale des commentaires est de 280 caractères",
        allDiscussedNr: "Super, les {0} ont été discutés!",
        allDiscussed: "Super, tous les Feedforwards ont été discutés!",
        notEnoughAnswers:
            "Cette fois, il n'y a pas eu assez de membres qui ont rempli l'enquête",
        notEnoughAnswersTeamName:
            "Cette fois, pas assez de membres de l'équipe {0} ont rempli le questionnaire.",
        startPrep: "Profite des apports de l'équipe et commence à te préparer",
        expandQuestionComments: "Développez la question pour voir les commentaires",
        pickValidTeam: "Choisissez une équipe qui a participé à l'enquête",
        devGeneralExplanation:
            "Il s'agit de l'écart type de toutes les évaluations de l'enquête. Un chiffre plus petit signifie que les membres de l'équipe ont des opinions similaires. Un chiffre plus élevé signifie que l'équipe devrait probablement discuter de quelques sujets sur lesquels les opinions divergent.",
        avgGeneralExplanation:
            "Il s'agit de la moyenne de toutes les questions de l'enquête. Une moyenne plus faible signifie qu'il y a plus de potentiel d'amélioration.",
        signInWith: "Connectez-vous avec ",
        whatsNextTitle: "Quelle est la suite?",
        whatsNextText: "Renforcer {0}, une case à cocher à la fois",
        viewDiscussion: "Voir discussion",
        startDiscussion: "Commencer la discussion",
        manageSurvey: "Gérer l'enquête",
        discussionFor: "Discussion pour",
        helpTitle: "Conseils et astuces",
        helpText: "Une aide pour discuter des résultats est disponible ici.",
        finishSurvey: "Fin de l'enquête",
        reviewResults: "Préparer la discussion",
        reviewResultsHint: "Consultez et triez les questions",
        scheduleMeeting: "Organisez une réunion d'équipe",
        scheduleMeetingHint:
            "Organisez une réunion d'équipe d'environ 45 minutes en dehors de cette application pour la discussion.",
        doDiscussion: "Discutez des résultats en équipe",
        doDiscussionHint:
            "Cliquez sur 'Commencer la discussion' en haut de la page. Discutez de Feedforwards en équipe et créez des tâches si nécessaire.",
        resultText1:
            "Ici, vous pouvez revoir les questions et modifier leur ordre de discussion.",
        resultText2:
            "Pour réorganiser les questions en fonction de leur importance, cliquez et faites glisser l'icône {0}.",
        handBuchText: "Guide de préparation",
        thisTimeNoFF: "Cette fois, il n'y a {0} de votre équipe",
        bravo: "Bravo",
        wow: "Wow",
        ffFromTeam: "de votre équipe",
        discussionCompletedPraise: "Super, tu as discuté de tous les Feedforwards!",
        showStats: "Afficher les statistiques",
        hideStats: "Masquer les statistiques",
        questionLegend: "Légende des questions",
        orderExplanation: "Les questions seront en mode discussion dans cet ordre",
        avgExplanation:
            "Un code de couleur indique dans quelle mesure les membres de l'équipe ont évalué cette question. Plus le chiffre est grand, mieux c'est!",
        devExplanation:
            "Le code couleur reflète les différences d'opinion au sein de l'équipe. Un chiffre plus petit signifie que les membres de l'équipe ont des opinions similaires. Un chiffre plus élevé signifie que l'équipe devrait discuter de ce sujet.",
        nrFFs: "Nombre de Feedforwards",
        nrFFExplanation:
            "Nombre de commentaires laissés par les membres de l'équipe pour cette question",

        adminLvls: "Niveau d'administration",
        profession: "Profession",
        gender: "Genre",
        office: "Bureau",
        location: "emplacement",
        age: "Âge",
        keyboardInfoMessage:
            "Tu peux cliquer dans le sondage en utilisant le clavier à touches numériques.",
        editAnswers: "Editer les réponses",
        activeSurvey: "Enquête active",
        activeSurveys: "Enquêtes actives",
        surveyTeamLoadError: "La liste des équipes ne peut pas être affichée : {0}",
        peopleWereInvitedFrom:
            "{0} personnes {1} ont été invitées à répondre à l'enquête",
        fromEntity: "de {0}",
        fromWholeCompany: "de toute l'entreprise",
        personFilledOut: "1 personne a rempli l'enquête",
        peopleFilledOut: "{0} personnes ont répondu à l'enquête",
        discussionVideoTitle: "Impuls Team Discussion",

        gdpr01: "Déclaration de consentement",
        gdpr02: "Merci d\'utiliser Joineer. \n" +
            "Nous souhaitons t\'informer que nous collectons les informations personnelles suivantes : ton nom et ton adresse e-mail. En cas de connexion par téléphone portable ou d\'authentification à deux facteurs, également ton numéro de téléphone portable. \n" +
            "Si ton entreprise souhaite évaluer les résultats en fonction de caractéristiques sociodémographiques (p. ex. âge, groupe fonctionnel), ces données seront également collectées.\n" +
            "Tu peux consulter tes informations personnelles à tout moment via le lien vers l\'application.",
        gdpr03: "Nous enregistrons également tes informations quantitatives et qualitatives sur les résultats (valeurs et feedforwards).",

        gdpr04: "",
        gdpr04pub: "",

        // not shown when company settings.anonym = true
        gdpr05: "",

        gdpr06: "",

        // not shown when company settings.anonym = true
        gdpr07: "",
        gdpr08: "",
        gdprLinkMessage: "Tes informations sur les résultats peuvent être consultées par les membres de ton équipe, les administrateurs de domaine et de l'entreprise. \n" +
            "Toutes les données sont utilisées dans un but précis et sont transmises à des tiers mandatés uniquement dans le cadre de notre accord", //For more information click {link}
        gdprLinkTitle: "de protection des données", //here
        gdprLinkUrl: "https://joineer.com/datenschutz/", //https://www.app.com
        gdpr09: "Si tu as des questions, n'hésite pas à nous contacter : info{'@'}joineer.ch",
        gdpr10: "Merci beaucoup pour ta confiance !",
        gdpr11: "Meilleures salutations",
        gdpr12: "Joineer AG",
        gdprIgiveMyConsent: "Je donne mon accord",

        introTitle1: "Bienvenue au Teambaromètre",
        introTitle2: "Ton opinion est importante et elle compte à tout moment.",
        introTitle3: "FeedFORWARD - pas un Feedback",
        introTitle4: "Remplir - Discuter - Profiter",
        introTitle5: "Comment cela fonctionne-t-il?",
        introText1:
            "Nous sommes très contents de ta participation - parce que ton opinion est importante. Il est important de lire les pages suivantes avec attention.",
        introText2:
            "Avec Joineer, on te demande ton opinion quelques fois par an - pour tâter le pouls.  De cette manière, il est possible de mesurer la situation au fil du temps et de comparer les améliorations en conséquence.",
        introText3:
            "Nous ne sommes pas une boîte à réclamations. Ne critique pas ce qui a mal tourné dans le passé, mais partage comment on peut faire mieux à l'avenir. Positif, orienté vers l'avenir et vers les solutions - Feedforward!",
        introText4:
            "Une fois que le sondage est terminé, les résultats seront visibles sur le tableau de bord. Il est essentiel de discuter des résultats au sein de l'équipe! Seulement de cette manière, le Teambarometer pourra montrer ses effets.",
        introText5L:
            "Tout est clair? N'hésite pas à nous contacter pour toute question. Accéde à la prochaine page avec ton e-mail et ton mot de passe et tu peux commencer!",
        introText5:
            "Tout est clair? Si tu as des questions, n'hésite pas à nous contacter à cette adresse E-mail: info{'@'}joineer.ch. Allons-y!",
        introSkip: "Sauter l'introduction",
        surveyToFill: "Enquête à remplir",
        hi: "Bonjour",
        surveyStartTitle: "Salut {0}, content de te voir ici.",
        startGoMessage:
            "Partage ce qui peut être mieux fait à l'avenir - positif et orienté vers des solutions avec Feedforward.",
        startGoMessage2:
            "Les résultats sont discutés et approfondis au sein des équipes.",
        pulseSurvey: "Enquête régulière",
        reference: "Remarque",
        surveyEndTitle: "Bravo, presque fini...",
        surveyEndMessage:
            'As-tu envoyé tous tes feedforwards ? Si oui, clique sur "Envoyer" maintenant. Si tu veux modifier quelque chose ou écrire d\'autres commentaires, tu peux utiliser le bouton "Retour" pour revenir aux questions.',
        surveyEndLowRatingWarning: "Tu as donné à toutes les questions un 1, la valeur la plus basse. Es-tu sûr de vouloir publier les résultats de cette manière?",
        startSurvey: "Commencer l'enquête",
        questionsInSurvey: "Questions de l'enquête",
        pickFromCatalog: "Parcourir le catalogue",
        createQuestion: "Créer une question",
        sendReminderTooltip: "Envoyer un e-mail de rappel",
        sendReminders: "Envoyer {0} rappels",
        sendReminder: "Envoyer 1 rappel",
        addMembers: "Ajouter des membres",
        addMember: "Ajouter un membre",
        addAdmin: "Ajouter l'admin",
        availableSoon: "Disponible bientôt!",
        remind: "Rappeler",
        remindAll: "Rappeler à tous",
        respondedQ: "Rempli?",
        hasNotFilled: "Ce membre n'a pas rempli l'enquête",
        hasFilled: "Ce membre a rempli l'enquête",
        membersPerPage: "Membres par page",
        yes: "oui",
        no: "non",
        endSurvey: "Fin de l'enquête",
        general: "Généraux",
        surveysPaused: "Des enquêtes régulières sont interrompues",
        noIntervalResults: "Aucun résultat d'intervalle disponible",
        status: "Statut",
        results: "Résultats",
        planned: "PRÉVUE",
        ongoing: "EN COURS",
        finished: "FINI",
        surveyTitle: "Titre de l'enquête",
        startDate: "Date de début",
        endDate: "Date de fin",
        participants: "Participants",
        total: "Total",
        totalParticipants: "Total des participants",
        page: "Page",
        previousPage: "Page précédente",
        nextPage: "Page suivante",
        or: "ou",
        none: "aucun",
        today: "aujourd'hui",
        here: "ici",
        select: "Sélectionner",

        roles: {
            superadmin: "Administrateur de l'entreprise",
            admin: "Team admin",
            member: "Membre",
        },
        greeting: "Bienvenue",
        buttons: {
            settings: "Options",
            saveChanges: "Sauvegarder les changements",
            reload: "Rechargez",
            filter: "Filtre",
            close: "Fermer",
            surveyGo: "Commencer le sondage",
            surveyEnd: "Envoyer",
            go: "Allons-y",
            start: "Commencer",
            cancel: "Annuler",
            reset: "Réinitialiser",
            resetAll: "Réinitialiser tout",
            resetSelection: "Réinitialiser la sélection",
            back: "Retour",
            backdash: "Retour au tableau de bord",
            reloadComponent: "Recharger le composant",
            next: "Prochain",
            finish: "Finaliser",
            finalise: "Finaliser",
            save: "Sauvegarder",
            ok: "D’accord",
            reopen: "Réouvrir",
            delete: "Effacer",
            send: "Envoyer",
            markDone: "Discuté",
            done: "Terminée",
            moveUp: "Déléguer",
            newTask: "Nouvelle tâche",
            expand: "Développer tout",
            collapse: "Minimiser sous",
            details: "Détails",
            new: "Nouveau",
            edit: "Editer",
            add: "Ajouter",
            addLanguage: "Ajouter une langue",
            addQuestion: "Ajouter une question",
            create: "Créer",
            askExpert: "Demandez à l'expert",
            goTo: "Aller sur",
            showAllFF: "Afficher tous les Feedforwards",
            hideAllFF: "Cacher tous les Feedforwards",
            changeOrder: "Ordre de modification",
            continue: "Suivant",
            yesDelete: "Oui, effacer",
            yesRemove: "Oui, enlever",
        },
        snackbar: {
            detailsUpdated: "Détails mis à jour",
            remindersSent: "Les rappels sélectionnés sont envoyés !",
            ffClosed: "Feedforward marqué comme discuté",
            ffOpened: "Feedforward ouvert",
            ffEscalated: "Feedforward envoyé à la direction pour l'évaluation.",
            taskClosed: "Tâche terminé",
            taskOpened: "Tâche réouverte",
            taskCreated: "Tâche crée",
            taskDeleted: "Tâche eliminée",
            taskDelegated: "Tâche déléguée",
            emailSent: "E-mail envoyé!",
            emailConfirmationSent: "E-mail de confirmation envoyé!",
            error: "Erreur",
            warning: "Avertissement",
            success: "Succès",
            forgotPWemailSent: "E-mail envoyé si le compte existe",
            copiedToClipboard: "Copié dans le presse-papier",
            downloading: "Télécharger",
            downloadError: "Télécharger l'erreur!",
            questionAdded: "Une nouvelle question a été ajoutée",
            questionDeleted: "La question a été supprimée",
            questionEdited: "La question a été modifiée",
            reordered: "Les questions ont été réorganisées",
            surveyDeleted: "L'enquête est supprimée",
            memberAdded: "Un membre a été ajouté à l'enquête",
            memberRemovedFromSurvey: "Le membre a été retiré de l'enquête",
            startChanged: "La date de début de l'enquête a été modifiée",
            endChanged: "La date de fin de l'enquête a été modifiée",
            titleChanged: "Le titre de l'enquête a été modifié",
            memberRemoved: "Le membre a été supprimé",
            adminRemoved: "Admin a été supprimé",
            teamCreated: "L'équipe a été créée",
            structureCreated: "La structure a été créée",
            teamDeleted: "L'équipe a été supprimée",
            structureDeleted: "La structure a été supprimée",
            structureRenamed: "La structure a été renommée",
            teamRenamed: "L'équipe a été renommée",
            teamMoved: "L'équipe a été déplacée",
            structureMoved: "La structure a été déplacée",
            newMemberCreated: "Un nouveau membre a été créé",
            newAdminCreated: "Un nouvel admin a été ajouté",
            surveyEnded: "La fin de l'enquête a été planifiée et elle sera terminée dans les 15 prochaines minutes.",
            surveyUpdated: "L'enquête a été mise à jour",
            useImportTool: "Pour créer des structures de niveau inférieur, veuillez utiliser l'outil d'importation Excel ou contacter le support Joineer.",
            feedbackSubmitted: "Ton retour nous aide à nous améliorer. Merci !",
        },
        tooltips: {
            editTeamName: "Modifier le nom",
            deleteTranslations: "Supprimer les traductions en {lang}",
            discussed: "Marquer Feedforward comme discuté",
            createTask: "Créer une tâche à partir du Feedforward",
            createFreeTask:
                "Créer une tâche qui n'est pas reliée à un Feedforward spécifique",
            closeTask: "Marquer la tâche comme terminée",
            moveUp: "Déplacer la tâche aux administrateurs.",
            deleteTask: "Éliminer la tâche",
            reopenFF: "Réouvrir Feedforward",
            addComment: "Ajouter un commentaire",
            discussedDisabled:
                '"Marquer Feedforward comme discutée" désactivé en mode aperçu.',
            disabled: "Désactivé en mode aperçu",
            hideFF: 'Marquer Feedforward comme "traité dans une discussion individuelle" et ne pas le montrer en mode Discussion',
            showFF: "Afficher le Feedforward en mode discussion",
            required: "Obligatoire",
            pressEnter: "Clique sur Enter pour sauvegarder",
            surveyCanNotBeDeleted:
                "Seules les enquêtes planifiées peuvent être supprimées.",
        },
        nav: {
            structure: "Structure de l'organisation",
            companyAdmins: "Administrateur d'entreprise",
            calendar: "Calendrier des enquêtes",
            dashboard: "Tableau de bord",
            discussion: "Mode discussion",
            reports: "Rapports",
            delegatedTasks: "Tâches déléguées",
            teamTasks: "Tâches de l'équipe",
            team: "Équipe",
            members: "Membres",
            interval: "Intervalle",
            adhoc: "Adhoc enquêtes",
            questions: "Questions",
            profile: "Profil",
            logout: "Déconnexion",
            intercomChat: "Chattez avec nous!",
            qrCodes: "Lettres QR",
            tfa: "Authentification à deux facteurs",
            ffanalysis: "FeedForward Analysis",
            feedForwards: "FeedForwards",
            emailTemplates: "Emails",
            surveyDefinitions: "Définitions de l'enquête",
        },
        selectSurvey: {
            customised: "Personnalisé",
            choose: "Sélectionne un sondage à afficher en mode Discussion",
            now: "Maintenant",
            team: "Équipe",
            selectTeam: "Sélectionne l'équipe",
            placeholder: "Sélectionne le sondage",
            goDiscussion: "Va au mode discussion",
            selectModeLabel: "Sélectionne le mode de visualisation",
            preview: "Mode aperçu",
            prepare: "Préparer",
            previewBody:
                "Ceci est un aperçu du mode de discussion utilisé avant de la réunion d'équipe effective. Il est possible de masquer certains Feedforwards afin de les traiter dans une réunion individuelle.",
            present: "Présenter",
            presentBody:
                "Présentation et discussion des résultats dans l'équipe.{0} Les Feedforwards sont transformables en activités d'équipe.",
            noTeam: "Cette équipe n'existe pas",
            noSurvey: "Il n'y a pas encore de sondage terminé.",
            comeBack:
                "Reviens pour discuter des résultats une fois que ton premier sondage sera terminé!",
            handbuchLink:
                "Tu trouveras {here} des conseils et des astuces pour discuter avec succès des résultats au sein de l'équipe",
            questionOrder: "Ordre de discussion",
            original: "comme dans l'enquête",
            overall: {
                average: "Moyenne générale",
                deviation: "Écart-type général",
            },
        },
        discussionMode: {
            question: "Question",
            survey: "Sondage",
            anonym: "Anonyme",
            noFF: 'Pas de Feedforward, clique sur "Prochain".',
            noDiscussed:
                "Les Feedforwards marqué comme discuté {0} apparaîtront ici",
            noTasks:
                "Les Feedforwards qui ont été transformés en tâches {0} sont affichés ici.",
            createTask: "Créer une tâche à partir du Feedforward",
            feedforwards: "Feedforwards",
            discussed: "Discuté",
            tasks: "Tâches",
            moveUpTo: "Déplacer la tâche à :",
            highlight: "Déléguer le Feedforward",
            describe: "Explique la raison qui justifie la transmission",
            selectLabel: "À l'attention de:",
            unable: "Impossible à régler dans l'équipe ?",
            hiddenFFs:
                "Les Feedforwards ci-dessous sont masqués dans la présentation en mode Discussion. Le nombre de Feedforwards masqués sera toujours affiché.",
            nrhiddenFFs:
                "Nombre de Feedforwards traités dans une réunion individuelle:",
        },
        achievements: {
            title: "Réalisations",
            intro: "Voici ce qui a déjà été réalisé",
        },
        tasks: {
            taskPage: "Page des tâches",
            taskBannerText:"Tâches créées à partir de la discussion",
            createTitle: "Créer une tâche",
            taskDisabled: "La création de tâches est désactivée en mode aperçu",
            titlePlaceholder: "Titre de la tâche",
            title: "Titre",
            commentPlaceholder: "Commentaire facultatif",
            origin: "Originaire de",
            addComment: "Ajouter un commentaire",
            comments: "commentaires",
            noComments: "Sans commentaires",
            dueDate: "Date d'échéance",
            dueBy: "A cause de",
            setDueDate: "Fixer une date d'échéance",
            assign: "Assigner l'activité à",
            assignedTo: "Assigné à",
            other: "autre",
            enterTitle: "Indique le titre de la tâche",
            myTasks: "Mes tâches",
            teamTasks: "Tâches de l'équipe",
            openTasks: "Tâches ouvertes",
            completedTasks: "Tâches terminées",
            deleteTitle: "Élimine la tâche",
            deleteBody:
                "Es-tu sûr de vouloir éliminer définitivement cette activité ?",
            noTasks: "Aucune tâche ouverte",
            delegatedExplanation:
                "Liste des Feedforwards ou des tâches d'équipe qui ont été déléguées à l'attention des admins.",
            noCompletedTasks: "Aucune tâche terminée pour le moment",
            assigneeRequiredMessage: "Sélectionne un membre à qui attribuer la tâche",
            noDelegatedTasks: "Pas encore de tâches déléguées",
            sendEmail: "Envoyer le résumé de la tâche par e-mail",
            calendarEntry: "Avec entrée de calendrier",
            calendarEntryNo: "Sans entrée de calendrier",
            commentDeletedBy: "Commentaire éliminé par {updatedBy}",
            deleteComment: "Éliminer ce commentaire ?",
            updateComment: "Sauvegarder les modifications",
            edited: "modifié",
            editComment: "Modifier le commentaire",
            afterDelete:
                "Une fois éliminé, ceci apparaîtra dans la chronologie des commentaires des tâches :",
        },
        completion: {
            title: "Bravo!",
            tasksCreated: "Tâches crées",
            ffDiscussed: "Feedforwards discuté",
            go: "Aller au tableau des tâches",
        },
        intro: {
            title: "Sélectionne l'ordre des questions",
            intro: "Sur la base des résultats, nous recommandons de discuter les Feedforwards dans l'ordre suivant. Fais glisser {0} sur les items pour changer l'ordre.",
            start: "Commencer la discussion",
        },
        login: {
            title: "Connexion",
            pw: "Mot de passe",
            forgot: "Mot de passe oublié?",
            withLink: " avec lien direct",
            withPassword: " avec mot de passe",
            checkEmail:
                "Si un compte existe avec cette adresse e-mail, ton lien de connexion te sera envoyé maintenant.",
            incorrectEmail: "Courriel incorrect ou manquant",
            labelSendLink: "Envoyer le lien",
            labelLinkSent: "Demande réussie",

            enterNameAndPassword:
                "Entre ton e-mail et ton mot de passe maintenant.",
            enterEmail:
                "Entrez votre e-mail pour récupérer un lien de connexion directe.",
            resetSent: "L'e-mail pour définir le mot de passe a été envoyé. ",
            getLink: "Récupérer le lien d'accès",
            selectYourCompany: "Sélectionnez votre enquête/entreprise"
        },
        admin: {
            title: "Admin board",
            allTeams: "Toutes les équipes",
            allSurveys: "Tous les sondages",
            selectedTeams: "Équipes sélectionnées",
            deselectAll: "Désélectionner tout",
            selectAll: "Sélectionner tout",
            created: "Créé le",
            markDone: "Marqué comme résolu",
            done: "Résolu",
            resolved: "Résolu",
            latestComment: "Dernier commentaire",
            lessComments: "moins de commentaires ...",
            moreComments: "plus de commentaires...",
            empty: "Aucune information ici pour le moment",
            deleteConfirmation: "Es-tu sûr de vouloir l'éliminer définitivement ?",
        },
        stats: {
            decreased: "diminué",
            increased: "augmenté",
            avg1: "est le résultat moyen de l'équipe pour cette question. Une moyenne inférieure indique qu'il existe une marge d'amélioration.",
            avg2: "En comparaison avec le sondage précédent, le résultat a {0} de {1}%.",
            dev1: "est l'écart-type des réponses à cette question. Un écart-type bas indique que les opinions de l'équipe sont similaires. Plus le numéro est grand, plus il est probable qu'il y ait un potentiel de discussion au sein de l'équipe.",
            dev2: "Comparé à le sondage précédent, les différences sont {0} de {1}.",
            graphIntro:
                "Le graphique ci-dessous peut aider à interpréter les résultats",
            devGraph1Label: "Unanime",
            devGraph1Explanation: "Un seul cœur et une seule âme.",
            devGraph2Label: "Différent",
            devGraph2Explanation: "Déviations dans la marge normale.",
            devGraph3Label: "Nécessité de discuter",
            devGraph3Explanation: "La situation est évaluée différemment.",
            avgGraph1Label: "Amélioration",
            avgGraph1Explanation:
                "Il y a un potentiel d'amélioration considérable.",
            avgGraph2Label: "Neutre",
            avgGraph2Explanation: "Suffisant. Améliorer de manière focalisée.",
            avgGraph3Label: "Continuer ainsi",
            avgGraph3Explanation: "Faire et recevoir des appréciations.",
            histogramTitle: "Distribution des réponses",
            histogramNr: "Nombre de personnes",
            histogramGrade: "Grade",
            histogramAnswered: " personnes ont répondu ",
        },
        report: {
            title: "Rapport du Teambarometer",
            selectLabel: "Visualiser le rapport de ",
            summary: "Résumé",
            all: "Tous",
            pleaseWait: "Chargement du rapport... un moment, s'il vous plaît",
            noData: "Aucun rapport disponible",
            searchLabel: "Recherche",
            name: "Nom",
            totalFF: "Total des Feedforwards",
            noFFsInSurvey: "Aucun Feedforward n'a été laissé dans cette enquête",
            closedFF: "Feedforwards discutés",
            totalTasks: "Total des tâches",
            taskedFF: "Tâches réalisées a partir d'un Feedforwards",
            delegatedTasks: "Tâches déléguées",
            closedTasks: "Tâches terminées",
            wholeCompany: "L'ensemble de l'entreprise",
            advancedTeamCompare: "Comparaison des équipes",
            discussionSummary: "Résumé de la discussion",
            discussionSummaryText:
                "Aperçu des discussions sur les résultats tenues au sein de l'entreprise. Vérifiez dans quelles équipes les résultats ont déjà été discutés ou mis en œuvre.",
            download: "Télécharger",
            downloadSummary: "Résumé du téléchargement (.pptx)",
            preparingFile: "Préparation du fichier...",
            presentation: "Présentation",
            matrixTitle: "Matrix heatmap",
            matrixText:
                "Aperçu, avec code couleur, des réponses par équipe et par question.",
            matrixTextMinFill:
                "Les équipes qui n'ont pas suffisamment de réponses sont exclues du rapport.",
            selectReport: "Sélectionner le rapport",
            displayError:
                "Le tableau ne peut pas être généré. Veuillez essayer de télécharger les résultats à la place.",
            averages: "Moyennes",
            deviations: "Écart type",
            enps: "ENPS",
            enpsScore: "Classement ENPS",
            answerNr: "Nombre de réponses",
            surveyEnded: "L'enquête s'est terminée",
            "1dayAgo": "il y a {0} jour",
            xdaysAgo: "il y a {0} jours",
            pastSurveys: "Histoire du sondage",
            endedAt: "Terminé à",
            team: "Équipe",
            teams: "Équipes",
            company: "Entreprise",
            companyGoal: "Objectif de l'entreprise",
            handleFeedforwards: "traiter toutes les Feedforwards",
            howManyHandled: "{0} Feedforwards sur {1} ont été traités",
            handlingRate: "Taux de traitement",
            FFHandleTitleCompanyAdmin: "Comment vos équipes progressent-elles?",
            FFHandleTextCompanyAdmin:
                "Pour que l'enquête soit un succès pour les équipes, il est important de traiter tous les Feedforwards peu après l'enquête.",
            "2weeksafter":
                "2 semaines après l'enquête, le tableau est coloré par le taux de traitement des Feedforward. Les couleurs représentent le % de Feedforwards qui sont discutés ou affectés au sein de l'équipe.",
        },
        genericApiErrorMessage: "L’opération IT n’a pas pu être effectuée",
        errors: {
            selectUser: "Veuillez d'abord sélectionner un membre",
            submit: "Erreur de soumission de l'enquête",
            invalidFormat: "Format non valide",
            notPossibleRunningSurvey: "Impossible pendant une enquête en cours",
            oops: "Oups!",
            "error-4000":
                "Pas assez de données disponibles pour cette enquête et les balises sélectionnées",
            noData: "Pas de données",
            noDataYet: "Pas encore de données",
            notEnoughData:
                "Pas assez de données disponibles pour cette enquête et les balises sélectionnées",
            notEnoughResponseData: "Pas assez de données de réponse",
            displayError: "Le tableau ne peut pas être généré",
            generalError: "Quelque chose a mal tourné",
            featureNotEnabled:
                "Cette fonction n'est pas activée sur votre compte. Veuillez nous contacter à l'adresse info{'@'}joineer.ch ou au +41\u00A044\u00A0510\u00A038\u00A000 pour plus d'informations.",
            featureNotAllowed:
                "Cette fonction n'est pas activée pour votre rôle d'utilisateur",
            missingFields: "Veuillez remplir tous les champs",
            noPermission: "Pas de permission pour voir cette page",
            incorrectUrl: "URL incorrecte",
            incorrectEmail: "Format de l'e-mail incorrect",
            login400: "Courriel ou autres informations de connexion manquants",
            login401: "Le mot de passe ou le nom d'utilisateur est incorrect",
            login401token: "Le jeton de connexion est incorrect",
            login403: "Le compte est protégé par un mot de passe. Veuillez vous connecter avec votre mot de passe.",
            login403authMethodNotAllowed: "La méthode d'authentification « {method} » n'est pas autorisée pour votre compte d'entreprise. Veuillez contacter votre administrateur.",
            login403urlLoginNotAllowed: "La connexion par URL n'est pas autorisée. La connexion par mot de passe est nécessaire.",
            "enps-question-already-exists": "Une seule question peut être marquée comme question ENPS.",
        },
        dashboard: {
            participation: "Participation",
            participants: "Participants",
            feedforwards: "Feedforwards",
            pariticantsTooltip: "{0} sur {1} participants ont soumis leurs réponses à cette enquête.",
            feedforwadTooltip: "{0} feedforwards ont été soumis dans cette enquête",
            showCompanyHint: "Afficher également les données au niveau de l'entreprise",
            company: "Entreprise",
            companyLine: "Entreprise (ligne graphique)",
            companyStatistics: "Entreprise (statistiques)",
            allYourTeams: "Toutes vos équipes",
            allYourTeamsLine: "Toutes vos équipes (ligne graphique)",
            allYourTeamsStatistics: "Toutes vos équipes (statistiques)",
            surveySuccessfullySubmitted: "Enquête soumise avec succès",
            surveyFilledOutByOutOf: "{0} sur {1}",
            surveyResults: "Résultats de l'enquête",
            summary: "Résumé",
            users: "Utilisateurs",
            resultsFor: "Résultats pour",
            wholeCompany: "L'ensemble de l'entreprise",
            ownTeam: "Propre équipe",
            ownTeams: "Propres équipes",
            team: "Équipe",
            inTeam: "en {0}",
            adminOf: "admin: {0}",
            noTagsMessage: "Pour effectuer un filtrage basé sur les tags, ajoutez des tags aux utilisateurs",
            compareWith: "Comparez avec",
            compareExplanation:
                "Comparez les résultats avec les éléments suivants:",
            questionOverview: "Aperçu des questions",
            average: "Moyenne",
            deviation: "Écart-type",
            companyHistory: "Moyenne de l'entreprise sur les dernières enquêtes",
            compareHistory: "Moyenne des enquêtes précédentes",
            filterExplanation: "Filtrer les résultats de l'enquête par tags:",
            "dev-good": "D'accord",
            "dev-normal": "Diverse",
            "dev-bad": "Très diversifié",
            distributionAnswers: "Distribution des réponses",
            surveyDate: "Date de l'enquête",
            companyAvg: "Moyenne de l'entreprise",
            positive: "positif",
            negative: "négatif",
            noFFs: "Pas de Feedforward",
            perMember: "par membre",
            otherTags: "Autres tags",

            waitingSurvey: "En attendant de commencer le premier sondage le",
            waitingTeam: "Attends que ton équipe complète le sondage.",
            surveyStatus: "Statut du sondage:",
            meanWhile: "En attendant, tu peux:",
            here: "ici",
            addMembers: "Ajouter de nouveaux utilisateurs {0}",
            editQ: "Modifier les questions {0}",
            addQ: "Ajouter une question {0}",
            editInterval: "Modifier l'intervalle {0}",

            scheduleSurvey: "Planifie un sondage",
            takeSurvey: "Participe au sondage",
            editYourSurveyAnswersUntil: "Modifiez vos rép. (jusqu'au {0})",
            surveyInProgressTitle: "Sondage en cours",
            surveyStatusLoading: "chargement ...",
            surveyStatusRunning: "En cours",
            surveyStatusFilled: "En cours",
            surveyStatusScheduled: "Planifié",
            surveyStatusFirstScheduled: "Premier sondage planifié",
            surveyStatusWaiting: "En attente",
            surveyDetailsLoading: "",
            surveyDetailsRunning: "Tu n'as pas encore rempli le sondage. Partage tes idées jusqu'au {0} !",
            surveyDetailsRunningNonMember: "L'enquête en cours se terminera le {0} !",
            surveyDetailsFilled: "Tu peux mettre à jour tes réponses au sondage jusqu'au {0}",
            surveyDetailsFilledNonMember: "L'enquête en cours se terminera le {0} !",
            surveyDetailsScheduled: "Le sondage commence le {0}",
            surveyDetailsFirstScheduled: "En attente du premier sondage le {0}",
            surveyDetailsWaiting: "Planifie un sondage ...",
            surveyEditTitle: "Ta contribution est précieuse",
        },
        teamMemberDisplay: {
            title: "Membres ayant participé à l'enquête",
            noResponse: "Aucun membre de cette équipe n'a répondu à l'enquête",
        },
        questionEditor: {
            questionsTitle: "Questions",
            questionsIntro:
                "Les questions avec un arrière-plan {0} jaune sont des questions qui sont posées à toute l'entreprise ou à une unité supérieure. Les questions avec un fond {1} bleu ne sont posées qu'à l'unité sélectionnée.",
            questionsIntroType:
                "Les questions du même type présentent une icône de lien {0} lorsqu'on passe le curseur de la souris dessus. Les statistiques des sondages à ces questions sont comparées entre elles.",
            survey: "Sondage",
            company: "Société",
            companyQ:
                "Visualiser et modifier les questions relatives à la société que chaque équipe a dans son sondage.",
            teamSelectLabel: "Voir les questions pour:",
            currentSurvey: "Enquête en cours",
            pastSurvey: "Sondage précédent",
            nextSurvey: "Prochain sondage",
            noEdit: "Les sondages précédents sont ici à titre de référence et ne peuvent pas être édités.",
            adminQuestionNoEdit:
                "Cette question est fixée au niveau de la société ou du département. Seul un administrateur du département ou de la société est autorisé à le modifier.",
            notAsked: "Pas demandé",
            noQuestionEdit:
                "Cette question est prioritaire et ne peut être modifiée",
            noQuestionEditView:
                "Il est uniquement possible de modifier cette option au niveau supérieur du département ou de la société.",
            addError: "La question ne peut pas être ajoutée.",
            editError: "La question ne peut pas être modifiée.",
            deleteError: "La question ne peut pas être supprimée.",
            nextError: "Le prochain sondage ne peut pas être récupéré.",
            catalogIntro:
                "Laisse-toi inspirer par notre catalogue de questions. Ouvre les catégories et sélectionne une question en cliquant dessus. Une fois que tu as attribué une question à ton sondage, tu peux modifier l'illustration et le texte en cliquant sur l'icône Modifier {0}.",
            questions: {
                question: "Question",
                type: "Taper",
                illustration: "Illustration",
                topic: "Thème",
                preview: "Aperçu",
                dragOrder: "Tire {0} et lâche pour changer l'ordre des questions.",
                addQuestion: {
                    en: "Anglais",
                    de: "Allemand",
                    it: "Italien",
                    fr: "Français",
                    pl: "Polonais",
                    sk: "Slovaque",
                    hu: "Hongrois",
                },
                mandatory: "Langue obligatoire",
                language: "Langue",
                chooseLang: "Sélectionner la langue",
                example: "Exemple:",
                exampleQ: "Es-tu satisfait du contenu de ton travail ?",
                exampleT: "Tâches professionnelles",
                removeLang: "Supprimer la langue"
            },
            addQuestion: {
                addTitle: "Ajouter une question au sondage",
                newTitle: "Nouveau",
                catalogTitle: "Catalogue",
                reuseTitle: "Réutiliser",
                new: "Crée ta propre question pour l'adapter aux exigences de ton équipe.",
                catalog: "Choisis une nouvelle question du catalogue",
                reuse: "Ajoute d'une question déjà utilisée dans le sondage.",
                noReuse: "Pas encore de Question-Histoire",
                newType:
                    "Crée une nouvelle question avec laquelle tu peux mesurer la perception de ton équipe sur une thématique spécifique.",
                newLinkedType:
                    "Crée une nouvelle question liée à la question précédemment utilisée",
                linkedTooltip1:
                    "Cette option permet de créer des questions qui mesurent la même chose mais qui sont formulées différemment ou sont posées sous un aspect nouveau.",
                linkedTooltip2:
                    "Relier des questions similaires permet de réaliser des évaluations concrètes et visuelles entre les sondages, comme indiqué dans la figure ci-dessous.",
                linkTitle: "Relier les questions",
                questionFormat: "Format des questions",
                enps: "ENPS",
                enpsLabelTooltip: "Cette question sera incluse dans le rapport Employee Net Promoter Score (eNPS), qui mesure la probabilité que les employés recommandent l'organisation.",
                enpsSwitchTooltip: "Activez pour inclure cette question dans le rapport Employee Net Promoter Score (eNPS), qui mesure la recommandation des employés.",
                textTitle: "Texte de la question",
                copyNotice:
                    "Les textes de la question sélectionnée ont été copiés ci-dessous pour vous aider.",
                noLangContact:
                    "Pour ajouter une langue qui n'est pas indiquée ici, veuille {0} et nous serons heureux de l'ajouter manuellement pour toi.",
                contact: "nous contacter",
                langsUsed:
                    "Toutes les {0} langues par défaut sont utilisées. Pour ajouter d'autres langues, veuille {1} et nous serons heureux de les ajouter manuellement pour toi.",
            },
            search: {
                mayAdd: "Il est possible d'ajouter une question déjà utilisée dans le sondage.",
                searchLabel: "Recherche entre les questions existantes",
                willBeLinked:
                    "Les statistiques rélatives à la question presente seront comparées avec celles des questions suivantes.",
                askedBefore: "Cette question a été précédemment posée dans :",
                allPreviousQuestionsLinked: "Toutes les questions précédentes ont déjà été liées ou ajoutées. Vous pouvez ajouter d'autres questions du catalogue ou en créer de nouvelles.",
            },
            dialogs: {
                editQ: "Modifier la question",
                editExplanation:
                    "Modifier le libellé, l'illustration ou ajouter de nouvelles langues",
                deleteExplanation: "Eliminer la question de ce sondage.",
                deleteTitle: "Eliminer la question",
                deleteConfirmation: "Veux-tu vraiment éliminer cette question ?",
                deleteLong:
                    "La question ainsi éliminée ne sera plus visible dans les prochains sondages, mais on pourra encore la voir dans les statistiques précédentes.",
            },
            questionFormats: {
                "NUMERIC_SCALE": {
                    label: "Échelle numérique",
                    description:
                        "Notez de 1 à 6, où 1 est la note la plus basse et 6 la note la plus élevée",
                },
                "OPEN_ENDED": {
                    label: "Ouvert",
                    description:
                        "Réponse en texte libre. C'est une bonne option pour le feedforward.",
                }
            }
        },
        forms: {
            search: "Recherche",
        },
        adhoc: {
            description1: "Enquêtes ponctuelles",
            surveyDetails: "Détails de l'enquête",
            detailsDescription: "Définir le titre et les dates",
            participantsDescription: "Choisissez qui remplit l'enquête",
            participantsMinFill1:
                "Il faudrait qu'au moins un membre remplisse l'enquête pour que les résultats soient calculés.",
            participantsMinFillLarger:
                "Il faudrait qu'au moins {0} membres répondent à l'enquête pour que les résultats soient calculés.",
            notEnoughMembers: "Pas assez de membres dans l'enquête! Minimum: {0}",
            questionsDescription: "Choisissez les questions pour l'enquête",
            review: "Revue",
            reviewDescription: "Résumé de l'enquête",
            activeFilters: "Filtres actifs",
            filterByEntities: "Filtre par entités",
            active: "actif",
            filterByTags: "Filtrer par étiquettes",
            reset: "Réinitialiser tous les filtres et supprimer les participants ajoutés à l'enquête",
            removeFromSurvey: "Retirer un participant de l'enquête",
            usersAddedToSurvey: " participants ajoutés à l'enquête",
            questionsAdded: " questions ajoutées à l'enquête",
            surveyPeriod: "Période de l'enquête",
            editParticipants: "Retourner et modifier les participants à l'enquête",
            editPeriod: "Retourner et modifier la période d'enquête",
            editQuestions: "Retourner et éditer les questions de l'enquête",
            editTitle: "Revenir en arrière et modifier le titre de l'enquête",
            datesWrongError:
                "Oops! Veuillez vous assurer que la date de fin est postérieure à la date de début",
            surveySuccess: "Enquête créée avec succès",
            surveyDeleteConfirmation:
                "Ês-tu sûr de vouloir supprimer cette enquête?",
            noMembersFound:
                "Aucun membre trouvé avec les filtres actuels. Essayez de supprimer certains des filtres actifs pour élargir la sélection ou réinitialisez les filtres {0}",
            manageSurvey: "Gérer l'enquête",
            endNow15min:
                "Cette action arrêtera l'enquête en cours. Les changements prendront effet dans 15 minutes.",
            endNowConfirmation:
                "Êtes-vous sûr de vouloir mettre fin à cette enquête?",
        },
        askExpert: {
            text: "Une situation difficile dans l'équipe? Vous ne savez pas comment discuter des résultats dans l'équipe? {0} se fera un plaisir de vous aider à répondre aux questions difficiles.",
            subject: "Objet",
            from: "De:",
            to: "À:",
            messagePlaceholder: "J'ai la question suivante...",
            askQuestions: "Si tu as des questions concrètes, ",
            yourExpertHere: "demande à ton expert ici",
        },
        survey: {
            admin: "Admin",
            team: "Équipe",
            opener: "Je pense que dans le futur, il serait bon que",
            publicComment: "Commentaire public",
            anonComment: "Commentaire anonyme",
            infoNumericalValueResponse:
                "Valeur numérique: Est saisie sans ton nom",
            infoAnonComment:
                "Commentaire anonyme: Est saisie sans ton nom",
            infoPublicComment:
                "Commentaire public : Ton nom est visible pour ton team et tes administrateurs",
            labelNoAnswerGiven: "Aucune réponse donnée",
            labelCantAnswer: "Je ne peux pas répondre à cette question",
            infoFeedforward: "Qu'est-ce qu'un bon Feedforward et comment l'écrire ? Regardez la vidéo",
            teamQuestion: "Question de l’équipe",
            whatIsAFeedforward: "Qu'est-ce que le feedforward ?",
            errors: {
                surveyFinished: "Cette enquête est déjà terminée, tu peux consulter les résultats sur le tableau de bord",
                getSurveyQuestion: "Une erreur s'est produite lors de la récupération de cette question",
                surveySettings: "Une erreur s'est produite lors de la récupération des paramètres",
                saveAnswer: "Une erreur s'est produite lors de l'enregistrement de cette réponse",
            }
        },
        remindOrSuspend: {
            resultsCannotBePrepared: "Implique tes membres",
            of: "des",
            teamMembersCompleted: "membres de l’équipe ont répondu au sondage",
            suspendOrRemind: "Rappeler ou suspendre",
            remind: "Rappeler",
            suspend: "Suspendre",
            membersWithIncompleteSurvey: "Membres des équipes",
            notifyRemindersSentAndSuspended: "Les membres de l'équipe ont été rappelés ou suspendus!",
            notifyRemindersSent: "Les membres de l'équipe ont été rappelés!",
            notifySuspended: "Les membres de l'équipe ont été suspendus!",
            errorSendingReminders: "Impossible de suspendre ou de rappeler",
            selectAll: "Sélectionner tout",
            searchMembers: "Rechercher des membres ou une équipe...",
            labelNoMember: "Aucun membre trouvé",
            all: "Tout",
            nobodyToSuspendOrRemind: "Tous les membres ont déjà rempli l’enquête",
            membersLeftToFillCurrentSurvey: "membre restant à remplir le sondage actuel | membres restants pour remplir le sondage actuel",
            membersHaveFilledOutTheCurrentSurvey: "membre a rempli le sondage actuel | les membres ont rempli le sondage actuel",
            surveyWillEndOn: "L'enquête se terminera le {0}",
            listOfMembersToRemindDidNotLoad: "La liste des membres à rappeler ou à suspendre n’a pas pu être récupéré",
            reloadRemindMemberList: "Recharger la liste de rappels",
        },
        tfa: {
            title: "Authentification à deux facteurs",
            description: "Pour ta sécurité, nous avons envoyé un code de confirmation à {0}. Merci de le saisir ci-dessous pour accéder à ton tableau de bord.",
            invalidCode: "Code non valide. Essaye encore.",
            smsSent: "SMS avec le code envoyé à {0}.",
            notReceived: "Tu n'as pas reçu le code?",
            resend: "Renvoyer",
            resendIn: "Renvoyer dans {0} secondes",
        },
        videoModal: {
            gotIt: "C’est clair"
        },
        feedforwards: {
            overallSummary: "Perspectives générales",
            summary: "Perspectives",
            aiTooltip: "Écrit par Joineer AI",
            teamQuestions: "Questions d'équipe",
            download: "Télécharger",
            summaryObservations: "Observations",
            summaryObservationsPlaceholder: "Veuillez fournir vos observations",
            searchFeedforwards: "Rechercher des feedforwards",
            noFeedforwardsFoundWith: "Aucun feedforward trouvé avec {0}, essayez un autre terme de recherche ou affinez votre recherche",
            copyToClipboard: "Copier les perspectives dans le presse-papiers",
            copyToClipboardSuccessMsg: "Perspectives copiées dans le presse-papiers",
            tags: {
                incomplete: "incomplet",
                inaccurate: "inexact",
                offensive: "offensant",
                disagree: "désaccord",
                badStyling: "mauvais style",
                agree: "d'accord",
                complete: "complet",
                correct: "correct",
                wellWritten: "bien écrit",
                easyToRead: "facile à lire",
                other: "autre",
            }
        },
        emailTemplateFields: {
            someLangsAreEmpty: "Certaines traductions de modèles n'ont pas de contenu",
            translations: "Traductions",
            hide: "Cacher",
            show: "Afficher",
            variables: "Variables",
            subject: "Objet:",
            title: "Titre:",
            greeting: "Salutation:",
            paragraph1: "Paragraphe 1:",
            buttonText: "Texte du bouton",
            paragraph2: "Paragraphe 2:",
            errorSavingTemplate: "Erreur lors de l'enregistrement du modèle",
            removeCustomisation: "Supprimer la personnalisation",
            removeCustomisedTemplateHint: "Cela supprimera ce modèle personnalisé pour '{0}' et le modèle par défaut sera envoyé à la place",
            cancel: "Annuler",
            save: "Enregistrer",
            deleteTemplateQuestion: "Souhaites-tu supprimer ce modèle d'e-mail?",
            deleteTemplateQuestionExplanation: "Après suppression, les modèles d'e-mail par défaut seront utilisés à la place.",
            yesDelete: "Oui, supprimer",
            emailSubjectLine: "Ligne d'objet de l'e-mail"
        },
        companyTemplates: {
            emailTemplate: "Modèle d'e-mail",
            emailTemplates: "Modèles d'e-mails",
            templateEdited: "Modèle modifié",
            backToTemplates: "retour aux modèles",
            badTemplateFormat: "Assure-toi que seules des variables valides sont utilisées : les variables sont listées ci-dessus et commencent toutes par {'{'}{'{'} et se terminent par {'}'}{'}'}. \nDetails: ",
            noTemplateSelected: "Aucun modèle sélectionné",
            emailNeededForTestSend: "(définissez votre email dans votre @:nav.profile pour recevoir des aperçus)",
            sendOnlyForEmails: "La prévisualisation ne peut être envoyée que pour les modèles d'e-mail",
            previewEmailSent: "E-mail envoyé à {0} (vérifiez les spams), en langue {1}",
            templateDeleted: "Modèle supprimé",
            templateCreated: "Modèle créé",
            SURVEY_PREPARATION: "préparation de l'enquête",
            SURVEY_START: "début de l'enquête",
            SURVEY_REMINDER: "rappel de l'enquête",
            SURVEY_END: "fin de l'enquête",
            DM_REMINDER: "rappel de DM",
            ADHOC_START: "début adhoc",
            ADHOC_REMINDER: "rappel adhoc",
            ADHOC_END: "fin adhoc",
            EMAIL: "e-mail",
            SMS: "sms",
            noAdhocUpsellPrompt: "Fonction Adhoc non activée. Veuillez contacter Joineer pour plus d'informations à {0} ou {1}",
            goToTemplatePage: "Aller à la page du modèle",
            preview: "aperçu",
            generalEMailSettings: "Paramètres généraux des e-mails",
            previewSendHint: "Envoie un test {0} à VOTRE {1}",
            email: "e-mail",
            mobile: "portable",
            saveAndSendTest: "Enregistrer & envoyer un test",
            sendTest: "Envoyer un test",
            errorSendingTemplate: "Erreur lors de l'envoi du modèle",
            error: "Erreur",
            channel: "Canal",
            sendPresurveyEmailTitle: "Envoyer un e-mail de pré-enquête",
            sendPresurveyEmailBody: "Vous êtes sur le point d'envoyer un e-mail de pré-enquête aux administrateurs d'équipe. Cet e-mail contient des informations sur l'enquête à venir et des instructions sur la manière d'ajuster les paramètres de l'équipe si nécessaire.",
            sendDMReminderEmailTitle: "Envoyer un e-mail de rappel DM",
            sendDMReminderEmailBody: "Vous êtes sur le point d'envoyer un e-mail de rappel DM aux administrateurs d'équipe. Cet e-mail contient des informations sur l'enquête à venir et des instructions sur la manière d'ajuster les paramètres de l'équipe si nécessaire.",
            sendEmailWarning: "L'envoi de ces e-mails notifiera tous les administrateurs d'équipe. Veuillez vous assurer que le contenu est correct avant de continuer.",
            sendEmailCheckbox: "J'ai vérifié le contenu de l'e-mail dans toutes les langues et je comprends que cette action est irréversible",
            sendEmailCancelButton: "Annuler",
            sendEmailButton: "Envoyer des e-mails",
            sendEmailSuccessDialog: "E-mail envoyé à {recipientsCount} administrateurs de l'équipe",
            event: "Événement",
            send: "Envoyer",
            action: "Action",
            details: "Détails",
            customise: "Personnaliser"
        },
        companyConfig: {
            all: "tous",
            companyConfigNote: "Ces paramètres s'appliquent aux {0} modèles d'e-mails pour cette entreprise. Pour ajuster ou modifier ces paramètres, veuillez contacter {1} ou {2}",
            backgroundColor: "Couleur de fond:",
            showBanner: "Afficher la bannière:",
            showBannerWithAColorBackground: "Afficher la bannière avec un fond coloré",
            bannerColor: "Couleur de la bannière:",
            showLogo: "Afficher le logo",
            ":": ":",
            logoSetupNote: "Pour configurer le logo de l'entreprise, veuillez contacter {0} ou {1}",
            showCompanyLogoInTheEMail: "Afficher le logo de l'entreprise dans l'e-mail",
            footerImage: "Image de pied de page:",
            footerLink: "Lien de pied de page:"
        },
        tiptap: {
            editLink: "Modifier le lien",
            url: "URL",
        },
        surveyDefinitions: {
            surveyDefinitions: "Définitions de l'enquête",
            definitionStatus: "Statut de participation",
            definitionSettings: "Paramètres",
            createSurveyDefinition: "Créer une définition d'enquête",
            title: "Titre",
            scheduleType: "Type d'horaire",
            active: "Actif",
            inactive: "Inactif",
            createdAt: "Créé à",
            actions: "Actes",
            manage: "Gérer",
            ongoing: "En cours",
            planned: "Prévu",
            done: "Fait",
            send: "Envoyer",
            searchParticipants: "Rechercher des participants",
            surveyTitle: "Titre de l'enquête",
            enterOffboardingInstructions: "Entrez les instructions",
            managers: "Gestionnaires",
            noManagersDefined: "Aucun gestionnaire défini",
            introText: "Texte d'introduction",
            questions: "Questions",
            noQuestionsAvailable: "Aucune question disponible",
            addQuestion: "Ajouter",
            sending: "Envoi de l'enquête",
            sendNewSurvey: "Envoyer aux participants",
            
            dashboard: {
                totalUsers: "Nombre total d'utilisateurs",
                totalParticipants: "Nombre total de participants",
                surveyCompletion: "Achèvement de l'enquête",
                comments: "Commentaires",
                avgSentiment: "Sentiment moyen",
                mainTopics: "Thèmes principaux",
                noTopics: "Aucun contenu disponible",
                department: "Département",
                score: "Score",
                qualitativeFeedback: "Rétroaction qualitative",
                aiSuggestions: "Suggestions d'IA",
                aiHighlights: "Points forts",
                results: "Résultats",
                questionResults: "Résultats des questions",
                sentimentOverTime: "Sentiment au fil du temps",
            }
        }
    };
};

function commonCustomisations() {

    return Object.assign({}, calidaGroup(), postGroup(), txGroup());

    function calidaGroup() {

        return assignToObjectKeys(CALIDA_GROUP_COMPANY_IDS, {
            introTitle1: "Bienvenue à tous pour le questionnaire Joineer !",
            introTitle3: "FeedFORWARD",
            introText3:
                "Nous voulons dépasser le stade du simple constat des problématiques. Faites-nous savoir comment nous pouvons nous améliorer à l'avenir. Orientés vers l'avenir, positifs et en recherche de solutions – « feedforward » en somme !",
            introTitle4: "Remplir – Discuter – Profiter",
            introText4:
                "Après le questionnaire, les résultats et les commentaires de l'équipe peuvent être consultés par les membres de l'équipe. Cette transparence en temps réel vous permet d'échanger sur les inputs et de discuter des mesures à prendre ! C'est ainsi que nous tirerons tous ensemble le meilleur bénéfice du questionnaire.",
            introTitle5: "Prêts à commencer ?",
            introText5:
                "Si vous rencontrez des problèmes techniques, veuillez svp vous adresser à Joineer à l'adresse suivante : info{'@'}joineer.ch. Pour des questions d'ordre général, merci d'écrire à joineer{'@'}calida.com. C'est parti !",
            gdpr02: "Merci d'utiliser Joineer. Nous souhaitons vous informer en toute transparence de notre politique de protection des données personnelles ainsi que de l'anonymat. A travers le questionnaires nous collectons les informations suivantes:",
            gdpr03: "a) Votre nom b) Votre adresse mail professionnelle, si vous en avez une c) Des évaluations quantitatives, de façon anonyme, sur une échelle de 1 à 6 d) Des commentaires qualitatifs dans les champs de texte:",
            gdpr04: "En accord avec notre culture d'entreprise au sein du CALIDA GROUP, basée sur la confiance et la transparence, nous vous offrons la possibilité, pour chacune des questions, de partager vos commentaires de manière transparente avec les membres votre équipe en indiquant votre nom. Les évaluations et les commentaires ne sont visibles que par vous-même, les membres de votre équipe et les responsables RH. L'accès aux données est volontairement restrictif selon le principe du \"seulement s'il existe un besoin de savoir\".",
            gdpr06: "Pour les personnes autorisées à accéder aux données, ces dernières sont facilement accessibles via l'e-mail d'invitation ou le QR code. Toutes les données sont sauvegardées sur des serveurs en Suisse et ne sont pas transmises à des tiers.",
            //gdpr07 hidden with current implementation, adding it to gdpr06
            gdpr07: "Pour les personnes autorisées à accéder aux données, ces dernières sont facilement accessibles via l'e-mail d'invitation ou le QR code.",
            gdpr09: "Si vous avez des questions sur la protection des données, vous pouvez nous contacter à l'adresse info{'@'}joineer.ch. Si vous avez des questions concernant l'anonymat, contactez-nous à l'adresse joineer{'@'}calida.ch.",
        }, {});
    }

    function postGroup() {

        return assignToObjectKeys(POST_GROUP_COMPANY_IDS, {
            introTitle1: "Bienvenue au Baromètre d'équipe de la Poste",
            introText1:
                "Nous nous réjouissons de ta participation. Ce sondage ne prendra que quelques minutes et est  relevé et analysé de manière personnalisée. Ainsi, tes réponses sont visibles pour les autres. Tu apprendras sur la page suivante comment répondre au baromètre d'équipe.",
            introTitle2: "De quoi ce sondage traite-t-il?",
            introText2:
                "Le baromètre d'équipe sert en premier lieu à recueillir des informations sur la manière dont tu perçois la culture pour toi personnellement, au sein de l'équipe et dans ton environnement de travail dans son ensemble  à l'heure actuelle. Le recueil de ces informations sert à ton équipe pour créer une compréhension commune de la culture actuelle afin d'en déduire ensuite des mesures de mise en œuvre du développement de la culture et de la transformation.\n\nMerci de répondre à ces questions de manière totalement spontanée et sincère, il n'y a pas de «bonne» ou de «mauvaise» réponse. Outre une évaluation quantitative, nous te prions de soumettre une proposition d'amélioration pour l'avenir. Merci de prévoir suffisamment de temps pour cela. Celles-ci revêtent une grande importance pour nous en tant qu'équipe afin de mieux comprendre tes évaluations et d'en dégager des mesures efficaces.\n\nClique à présent sur «Commencer le sondage» pour démarrer le baromètre d'équipe! Si tu rencontres des problèmes techniques en répondant à l'enquête, utilise la fonction de chat du baromètre d'équipe.",
            gdpr02: "Merci d'utiliser le Teambaromètre. Il est utilisé par la Poste Suisse en collaboration avec l'entreprise Joineer. Afin de respecter la politique de confidentialité, nous souhaitons t'informer que nous recueillons les informations suivantes en plus de ton nom et de ton adresse électronique :",
            gdpr03: "• Valeurs quantitatives anonymes aux questions du Teambaromètre.",
            gdpr04: "• Commentaires qualitatifs ou feedforwards personnels facultatifs.",
            gdpr04pub: "• Commentaires qualitatifs ou feedforwards personnels facultatifs.",
            gdpr05: "Les réponses peuvent être consultées par les membres de ton équipe, l'administrateur de l'équipe et l'administrateur de la société.",
            gdpr06: "Merci de ne pas partager de données sensibles ou d’informations confidentielles via Joineer. En donnant ton consentement, tu confirmes que tu prends cela en compte.",
            gdpr07: "",
            gdprLinkMessage: "Toutes les données sont utilisées à des fins spécifiques et, dans le cadre des dispositions relatives à la protection des données, sont transmises aux tiers suivants : ",
            gdprLinkTitle: "link",
            gdprLinkUrl: "https://joineer.com/wp-content/uploads/2024/03/Auftrags_Datenverarbeitung-nach-DSGVO.pdf",
            gdpr09: "Merci pour ton consentement.",
            gdpr10: "Tu peux consulter tes données personnelles à tout moment en cliquant sur le lien du Teambaromètre. Pour toute question, n'hésite pas à nous contacter à l'adresse info{'@'}joineer.ch",
            gdpr12: "Joineer et l’équipe de «Culture de demain»",
        }, {});
    }

    function txGroup() {

        return assignToObjectKeys(TX_GROUP_COMPANY_IDS, {
            //gdpr01: "",
            gdpr02: "Merci d'utiliser Joineer.\n\nPar la présente, nous souhaitons t'informer de manière transparente sur la politique de confidentialité et l'anonymat. Nous recueillons les informations suivantes dans le cadre de l'enquête :\n\na) ton nom\nb) ton adresse électronique professionnelle\nc) des évaluations quantitatives anonymes sous la forme d’une échelle de 1 à 10\nd) des commentaires qualitatifs dans les champs de texte\n\nLes résultats de ton équipe seront évalués et affichés si au moins trois membres de l'équipe ont participé à l'enquête. Dans ce cas, tes commentaires sont consultables personnellement ou de manière anonyme (selon ta configuration) pour les membres de ton équipe et pour ton supérieur direct. Tes commentaires anonymes seront vus par les responsables de ton département et par ton membre de la direction.\n\nLes données anonymes peuvent être partagées avec une entreprise partenaire à des fins d'analyse des données. Une fois l'analyse terminée, les données sont supprimées de l'entreprise partenaire.\n\nToutes les données sont stockées sur des serveurs en Suisse. Tu peux consulter tes données personnelles à tout moment via le lien de cette application.\n\nSi tu as des questions, n'hésite pas à nous contacter : info{'@'}joineer.ch.",
            gdpr03: "",
            gdpr04: "",
            gdpr05: "",
            gdpr06: "",
            gdpr07: "",
            gdpr08: "",
            gdprLinkMessage: "",
            gdprLinkUrl: "",
            gdprLinkTitle: "",
            gdprIgiveMyConsent: "Je comprends et je donne mon consentement",
            gdpr09: "",
            gdpr10: "",
            gdpr11: "",
            gdpr12: "Merci beaucoup pour ta confiance !\nCordialement\nJoineer",
        }, {});
    }
}

function customisations() {
    return {

        // Brugg Lifting
        "64983brl304366969": {
            gdpr01: "Déclaration de consentement",
            gdpr02: "Merci d'utiliser Joineer. \n" +
                "\n" +
                "Nous souhaitons t'informer que nous collectons les informations personnelles suivantes : ton nom et ton adresse e-mail. En cas de connexion par téléphone portable ou d'authentification à deux facteurs, également ton numéro de téléphone portable. \n" +
                "\n" +
                "Si ton entreprise souhaite évaluer les résultats en fonction de caractéristiques sociodémographiques (p. ex. âge, groupe fonctionnel), ces données seront également collectées.\n" +
                "\n" +
                "Tu peux consulter tes informations personnelles à tout moment via le lien vers l'application.\n" +
                "\n" +
                "Nous collectons en outre les informations de résultats suivantes :",
            gdpr03: "• Réponses quantitatives anonymes (valeurs)",

            gdpr04: "• Commentaires qualitatifs publics ou anonymes au choix (feedforwards)",
            gdpr04pub: "• Commentaires qualitatifs facultatifs.",

            gdpr05: "Pour ton entreprise, les résultats d'une équipe seront évalués et affichés si au moins le nombre suivant de personnes d'une équipe a participé à l'enquête : 3. Dans ce cas, les informations sur tes résultats seront visibles par les membres de ton équipe, les administrateurs du domaine et de l'entreprise.",
            gdpr06: "Toutes les données sont stockées sur des serveurs en Suisse et ne sont pas transmises à des tiers.",
            gdpr07: "",
            gdpr08: "Nous te demandons de observer ce point et d'accepter ces conditions.",
            gdprLinkMessage: "", //For more information click
            gdprLinkTitle: "", //here
            gdprLinkUrl: "", //https://www.app.com
            gdpr09: "Si tu as des questions, n'hésite pas à nous contacter : info@joineer.ch.",
            gdpr10: "Merci beaucoup pour ta confiance!",
            gdpr11: "Meilleures salutations",
            gdpr12: "Joineer AG",
            gdprIgiveMyConsent: "Je donne mon accord",
        },

        // TX Tamedia
        "23213tam223139668": {
            //gdpr01: "",
            gdpr02: "Merci d'utiliser Joineer.\n\nPar la présente, nous souhaitons t'informer de manière transparente sur la politique de confidentialité et l'anonymat. Nous recueillons les informations suivantes dans le cadre de l'enquête :\n\na) ton nom\nb) ton adresse électronique professionnelle\nc) des évaluations quantitatives anonymes sous la forme d’une échelle de 1 à 10\nd) des commentaires qualitatifs dans les champs de texte\n\nLes résultats de ton équipe seront évalués et affichés si au moins cinq membres de l'équipe ont participé à l'enquête. Dans ce cas, tes commentaires sont consultables personnellement ou de manière anonyme (selon ta configuration) pour les membres de ton équipe et pour ton supérieur direct. Tes commentaires anonymes seront vus par les responsables de ton département et par ton membre de la direction.\n\nLes données anonymes peuvent être partagées avec une entreprise partenaire à des fins d'analyse des données. Une fois l'analyse terminée, les données sont supprimées de l'entreprise partenaire.\n\nToutes les données sont stockées sur des serveurs en Suisse. Tu peux consulter tes données personnelles à tout moment via le lien de cette application.\n\nSi tu as des questions, n'hésite pas à nous contacter : info{'@'}joineer.ch.",
            gdpr03: "",
            gdpr04: "",
            gdpr05: "",
            gdpr06: "",
            gdpr07: "",
            gdpr08: "",
            gdprLinkMessage: "",
            gdprLinkUrl: "",
            gdprLinkTitle: "",
            gdprIgiveMyConsent: "Je comprends et je donne mon consentement",
            gdpr09: "",
            gdpr10: "",
            gdpr11: "",
            gdpr12: "Merci beaucoup pour ta confiance !\nCordialement\nJoineer",
        },

        // AWA Nullmessung: special texts:
        "19681ANU402980805": {
            gdpr05: "",
            startGoMessage:
                "Partage ce qui peut être mieux fait à l'avenir - positif et orienté vers des solutions avec Feedforward.",
        },

        // RUAG special texts:
        "651545c98fbb90562f4c065c": {
            gdpr01: "Déclaration de consentement",
            gdpr02: "Merci d'utiliser Joineer.\nNous souhaitons t'informer que nous collectons les informations personnelles suivantes : ton nom et ton adresse e-mail.\nTu peux consulter tes informations personnelles à tout moment en cliquant sur le lien de l'application.\n\nNous collectons également les informations de résultats suivantes :",
            gdpr03: "• Réponses quantitatives anonymes (valeurs).",
            gdpr04: "",
            gdpr05: "Les résultats sont visibles par les administrateurs du secteur et de l'entreprise.\nPour ton entreprise, les résultats sont visibles au niveau de l'équipe si au moins le nombre suivant de personnes d'une équipe a participé à l'enquête : 5.",
            gdpr06: "Toutes les données sont stockées sur des serveurs en Suisse et ne sont pas transmises à des tiers. Pour plus d'informations, voir Joineer Protection des données (https://joineer.com/datenschutz/).",
            gdpr07: "",
            gdprIgiveMyConsent: "Je donne mon accord",
            gdpr08: "",
            gdpr09: "Si tu as des questions, n'hésite pas à nous contacter : info{'@'}joineer.ch",
            gdpr10: "Merci beaucoup pour ta confiance !",
            gdpr11: "Meilleures salutations",
            gdpr12: "Joineer AG",
            introTitle1: "Bienvenue au Baromètre des collaborateurs RUAG",
            introTitle2: "Nous sommes là pour toi",
            introText1:
                "Nous sommes très heureux de ta participation - car ton avis compte !",
            introText2:
                "Si tu as des questions, n'hésite pas à nous contacter à l'adresse info{'@'}joineer.ch. C'est parti !",
            startGoMessage: "",
            surveyEndMessage: "Clique sur 'Envoyer' maintenant. Si tu veux modifier quelque chose, tu peux utiliser le bouton \"Retour\" pour revenir aux questions.",
        },

        //SRG special texts:
        "671222b7ff98fb6a8823cf83": {
            gdpr01: "Déclaration de consentement",
            gdpr02: "Merci d'utiliser Joineer.\nNous souhaitons t'informer que nous collectons les informations personnelles suivantes : ton nom et ton adresse e-mail. En cas de connexion par téléphone portable ou d'authentification à deux facteurs, également ton numéro de téléphone portable.\nTu peux consulter tes informations personnelles à tout moment via le lien vers l'application.\n\nNous collectons en outre les informations de résultats suivantes :",
            gdpr03: "• Réponses quantitatives (valeurs)",
            gdpr04: "• Commentaires qualitatifs (feedforwards)",
            gdpr05: "Pour ton entreprise, les résultats d'une enquête seront évalués et affichés si au moins le nombre suivant de personnes d'une équipe a participé à l'enquête : 1.\nDans ce cas, les informations sur tes résultats seront visibles par le créateur de l'enquête et les administrateurs de l'entreprise.",
            gdpr06: "Toutes les données sont stockées sur des serveurs en Suisse et ne sont pas transmises à des tiers.",
            gdpr07: "",
            gdprIgiveMyConsent: "Je donne mon accord",
            gdpr08: "",
            gdpr09: "Si tu as des questions, n'hésite pas à nous contacter : info{'@'}joineer.ch",
            gdpr10: "Merci beaucoup pour ta confiance !",
            gdpr11: "Meilleures salutations",
            gdpr12: "Joineer AG",
        },

        // DIS special texts:
        // "80568dis750356676": {
        "70129dis673402979": {
            introTitle1: "Welcome to Teambarometer",
            introText1:
                "A trustworthy and direct feedback discussion helps us and each individual to develop further. Such conversations are very important to us. We want to offer another possibility for feedback several times a year with Teambarometer from Joineer pulse surveys",
            introTitle3: "Shape the future with us!",
            introText3:
                "We, the management of the Digital Infrastructure Services business unit, want to understand what makes you tick and what can we do better. This survey is one way to let us know what you think. Use it and shape the future together with us!",
        },

        //KPT special texts
        "81812kpt446687047": {
            gdpr02: "C'est parti... tu pourras bientôt utiliser le «Teambarometer».",
            gdpr03: "Afin de respecter la législation sur la protection des données, nous souhaitons t'informer qu'en plus de ton nom et de ton adresse e-mail, nous collectons les informations suivantes: ",
            gdpr04:
                "• Les réponses quantitatives aux questions du «Teambarometer» ainsi que les commentaires qualitatifs.",
            gdpr04pub:
                "• Les réponses quantitatives aux questions du «Teambarometer» ainsi que les commentaires qualitatifs.",
            gdpr05: "Tes réponses sont visibles par les membres de ton équipe, ton responsable direct ainsi que par les responsables de processus.",
            gdpr07: "",
            gdpr12: "KPT & Joineer",
        },

        //Swissinfo special texts:
        "22801SWI182608558": {
            gdpr01: "Déclaration de consentement",
            gdpr02: "Merci d'utiliser Joineer. \nNous souhaitons t'informer que nous collectons les informations personnelles suivantes : ton nom et ton adresse e-mail. En cas de connexion par téléphone portable ou d'authentification à deux facteurs, également ton numéro de téléphone portable. \nSi ton entreprise souhaite évaluer les résultats en fonction de caractéristiques sociodémographiques (p. ex. âge, groupe fonctionnel), ces données seront également collectées. \nTu peux consulter tes informations personnelles à tout moment via le lien vers l'application.\n\nNous collectons en outre les informations de résultats suivantes :",
            gdpr03: "• Réponses quantitatives anonymes (valeurs)",
            gdpr04: "• Commentaires qualitatifs personnels (feedforwards)",
            gdpr04pub: "• Commentaires qualitatifs personnels (feedforwards)",
            gdpr05: "Pour ton entreprise, les résultats d'une équipe seront évalués et affichés si au moins le nombre suivant de personnes d'une équipe a participé à l'enquête : 1. Dans ce cas, les informations sur tes résultats seront visibles par les membres de ton équipe, les administrateurs du domaine et de l'entreprise.",
            gdpr06: "Toutes les données sont stockées sur des serveurs en Suisse et ne sont pas transmises à des tiers.",
            gdpr07: "",
            gdprIgiveMyConsent: "Je donne mon accord",
            gdpr08: "",
            gdpr09: "Si tu as des questions, n'hésite pas à nous contacter : info{'@'}joineer.ch",
            gdpr10: "Merci beaucoup pour ta confiance !",
            gdpr11: "Meilleures salutations",
            gdpr12: "Joineer AG"
        },

        //Fatzer special texts:
        "85916fat786585735": {
            gdpr01: "Déclaration de consentement",
            gdpr02: "Merci d'utiliser Joineer.\nNous souhaitons t'informer que nous collectons les informations personnelles suivantes : ton nom et ton adresse e-mail. En cas de connexion par téléphone portable ou d'authentification à deux facteurs, également ton numéro de téléphone portable.\n\nSi ton entreprise souhaite évaluer les résultats en fonction de caractéristiques sociodémographiques (p. ex. âge, groupe fonctionnel), ces données seront également collectées. Nous collectons en outre les informations de résultats suivantes:",
            gdpr03: "• Réponses quantitatives anonymes (valeurs)",
            gdpr04: "• Commentaires qualitatifs publics ou anonymes au choix (feedforwards)",
            gdpr05: "Pour ton entreprise, les résultats d'une équipe seront évalués et affichés si au moins le nombre suivant de personnes d'une équipe a participé à l'enquête: 3. Dans ce cas, les informations sur tes résultats seront visibles par les membres de ton équipe, les administrateurs du domaine et de l'entreprise.",
            gdpr06: "Toutes les données sont stockées sur des serveurs en Suisse et ne sont pas transmises à des tiers.",
            gdpr07:"Tu peux consulter tes informations personnelles à tout moment via le lien vers l'application.",
            gdprIgiveMyConsent: "Je donne mon accord",
            gdpr10: "Merci beaucoup pour ta confiance!",
            gdpr11: "Meilleures salutations",
            gdpr12: "Joineer AG"

        },

        // KuZu special texts:
        "91358txk194486012": {
            gdpr03: "• Réponses quantitatives anonymes aux questions de l'enquête de gestion CS.",
            gdpr05: "Les réponses données seront mises à la disposition des cadres supérieurs des CS et des administrateurs d'entreprise.",
            gdpr07: "",

            startGoMessage:
                "Partage ce qui peut être mieux fait à l'avenir - positif et orienté vers des solutions avec Feedforward.",
        },

        // Atupri
        "95444atp856638338": {
            introTitle1: "Bienvenue à cette enquête",
            introText2:
                "On te demande ton avis plusieurs fois par an, par pulsations. Cela permet de mesurer l'humeur au fil du temps et de comparer les améliorations en conséquence.",
            introText4:
                "Une fois que le sondage est terminé, les résultats seront visibles sur le tableau de bord. Il est essentiel de discuter des résultats au sein de l'équipe! Ce n'est qu'ainsi que l'entretien déploie ses effets.",
        },

        //Post Kommunikations services special texts
        "95462pks109762813": {  introTitle1: "Bienvenue au Baromètre d'équipe de la Poste",
          introText1:
              "Nous nous réjouissons de ta participation. Ce sondage ne prendra que quelques minutes et est  relevé et analysé de manière personnalisée. Ainsi, tes réponses sont visibles pour les autres. Tu apprendras sur la page suivante comment répondre au baromètre d'équipe.",
          introTitle2: "De quoi ce sondage traite-t-il?",
          introText2:
              "Le baromètre d'équipe sert en premier lieu à recueillir des informations sur la manière dont tu perçois la culture pour toi personnellement, au sein de l'équipe et dans ton environnement de travail dans son ensemble  à l'heure actuelle. Le recueil de ces informations sert à ton équipe pour créer une compréhension commune de la culture actuelle afin d'en déduire ensuite des mesures de mise en œuvre du développement de la culture et de la transformation.\n\nMerci de répondre à ces questions de manière totalement spontanée et sincère, il n'y a pas de «bonne» ou de «mauvaise» réponse. Outre une évaluation quantitative, nous te prions de soumettre une proposition d'amélioration pour l'avenir. Merci de prévoir suffisamment de temps pour cela. Celles-ci revêtent une grande importance pour nous en tant qu'équipe afin de mieux comprendre tes évaluations et d'en dégager des mesures efficaces.\n\nClique à présent sur «Commencer le sondage» pour démarrer le baromètre d'équipe! Si tu rencontres des problèmes techniques en répondant à l'enquête, utilise la fonction de chat du baromètre d'équipe.",
          gdpr02: "Merci d'utiliser le Teambaromètre. Il est utilisé par la Poste Suisse en collaboration avec l'entreprise Joineer. Afin de respecter la politique de confidentialité, nous souhaitons t'informer que nous recueillons les informations suivantes en plus de ton nom et de ton adresse électronique :",
          gdpr03: "• Valeurs quantitatives anonymes aux questions du Teambaromètre.",
          gdpr04: "• Commentaires qualitatifs ou feedforwards anonymes ou personnels facultatifs.",
          gdpr04pub: "• Commentaires qualitatifs ou feedforwards anonymes ou personnels facultatifs.",
          gdpr05: "Les résultats d'une équipe s'affichent si au moins 3 personnes d'une équipe ont participé au sondage. Les réponses peuvent être consultées par les membres de ton équipe, l'administrateur de l'équipe et l'administrateur de la société.",
          gdpr06: "Merci de ne pas partager de données sensibles ou d’informations confidentielles via Joineer. En donnant ton consentement, tu confirmes que tu prends cela en compte.",
          gdpr07: "",
          gdprLinkMessage: "Toutes les données sont utilisées à des fins spécifiques et, dans le cadre des dispositions relatives à la protection des données, sont transmises aux tiers suivants : ",
          gdprLinkTitle: "link",
          gdprLinkUrl: "https://joineer.com/wp-content/uploads/2024/03/Auftrags_Datenverarbeitung-nach-DSGVO.pdf",
          gdpr09: "Merci pour ton consentement.",
          gdpr10: "Tu peux consulter tes données personnelles à tout moment en cliquant sur le lien du Teambaromètre. Pour toute question, n'hésite pas à nous contacter à l'adresse info{'@'}joineer.ch",
          gdpr12: "Joineer et l’équipe de «Culture de demain»"
        },

        //BVGer services special texts
        "52780bvg479791730": {
            gdpr01: "Déclaration de consentement",
            gdpr02: "Merci d'utiliser Joineer. Nous souhaitons t'informer que nous collectons les informations personnelles suivantes : ton nom et ton adresse e-mail. En cas de connexion par téléphone portable ou d'authentification à deux facteurs, également ton numéro de téléphone portable.\nSi ton entreprise souhaite évaluer les résultats en fonction de caractéristiques sociodémographiques (p. ex. âge, groupe fonctionnel), ces données seront également collectées.\nTu peux consulter tes informations personnelles à tout moment via le lien vers l'application.\n\nNous collectons en outre les informations de résultats suivantes :",
            gdpr03: "• Réponses quantitatives anonymes (valeurs)",
            gdpr04: "• Commentaires qualitatifs publics ou anonymes au choix (feedforwards)",
            gdpr04pub: "• Commentaires qualitatifs facultatifs.",
            gdpr05: "Pour ton entreprise, les résultats d'une équipe seront évalués et affichés si au moins le nombre suivant de personnes d'une équipe a participé à l'enquête : 3. Dans ce cas, les informations sur tes résultats seront visibles par les membres de ton équipe, les administrateurs du domaine et de l'entreprise.",
            gdpr06: "Toutes les données sont stockées sur des serveurs en Suisse et ne sont pas transmises à des tiers.",
            gdpr07: "",
            gdpr08: "",
            gdprLinkMessage: "", //For more information click
            gdprLinkTitle: "", //here
            gdprLinkUrl: "", //https://www.app.com
            gdpr09: "Si tu as des questions, n'hésite pas à nous contacter : info{'@'}joineer.ch",
            gdpr10: "Merci beaucoup pour ta confiance !",
            gdpr11: "Meilleures salutations",
            gdpr12: "Joineer AG",
            gdprIgiveMyConsent: "Je donne mon accord",
        },

        //Tamedia pulse special texts
        "677d1ecb16c428775054f9de": {
            gdpr01: "Déclaration de consentement",
            gdpr02: "Merci de participer au Tamedia Pulse Check.\nNous souhaitons t'informer que nous collectons les informations personnelles suivantes : ton nom et ton adresse e-mail. En cas de connexion par téléphone portable ou d'authentification à deux facteurs, également ton numéro de téléphone portable.\nSi ton entreprise souhaite évaluer les résultats en fonction de caractéristiques sociodémographiques (p. ex. région de travail, groupe fonctionnel), ces données seront également collectées.\nTu peux consulter tes informations personnelles à tout moment via le lien vers l'application.\n\nNous collectons en outre les informations de résultats suivantes :",
            gdpr03: "• Réponses quantitatives anonymes (valeurs)",
            gdpr04: "• Commentaires qualitatifs anonymes (feedforwards)",
            gdpr05: "Pour ton entreprise, les résultats d'un département seront évalués et affichés si au moins quatre personnes ont participé à l'enquête. Dans ce cas, les informations sur tes résultats seront visibles par les membres du département, les administrateurs du département et de l'entreprise.",
            gdpr06: "Toutes les données sont stockées sur des serveurs en Suisse et ne sont pas transmises à des tiers.",
            gdpr07: "",
            gdpr08: "",
            gdprLinkMessage: "", //For more information click
            gdprLinkTitle: "", //here
            gdprLinkUrl: "", //https://www.app.com
            gdpr09: "Si tu as des questions, n'hésite pas à contacter mission.x{'@'}tx.group.",
            gdpr10: "Merci beaucoup pour ta confiance !",
            gdpr11: "Meilleures salutations",
            gdpr12: "Tamedia & Joineer AG",
            gdprIgiveMyConsent: "Je donne mon accord",
            introTitle1: "Bienvenue au Tamedia Pulse Check",
            introText2:
                "Avec le Tamedia Pulse Check, on te demande ton opinion chaque mois." +
                " De cette manière, il est possible de mesurer la situation au fil du temps et de comparer les améliorations en conséquence.",
        },
    };
}
