import { httpClient, cachedHttpClient } from './httpClient';

const END_POINT = '/takesurvey';
const END_POINT_SURVEYS = '/surveys';


const getSurveys = () => httpClient.get(END_POINT, { cache: { cleanupInterval: 200} });

const getSurvey = (survey_id) => httpClient.get(`${END_POINT}/${survey_id}`);

const getSurveyMembers = (survey_id) => httpClient.get(`${END_POINT}/${survey_id}/members`);

const getSurveyQuestion = (survey_id, question_index) => httpClient.get(`${END_POINT}/${survey_id}/question/${question_index}`)

const submitAnswer = (survey_id, question_index, answer) => httpClient.put(`${END_POINT}/${survey_id}/question/${question_index}/answer`, answer)

// Not really needed answer is also included in getSurveyQuestion call
//const getAnswer = (survey_id, question_index) => httpClient.get(`${END_POINT}/${survey_id}/question/${question_index}/answer`)

const submitSurvey = (survey_id, payload) => {
    if(payload){
        //payload example when user provides mobile phone on survey submit: { mobile:"12445466" }
      return httpClient.put(`${END_POINT}/${survey_id}/submit`, payload)
    }else{
      return httpClient.put(`${END_POINT}/${survey_id}/submit`)
    }
}

const getNonRespondent = (survey_id) => httpClient.get(`${END_POINT_SURVEYS}/${survey_id}/nonrespondent`);
const getSurveysWithStatus = (status) => httpClient.get(END_POINT_SURVEYS, { params: { status }, cache: { cleanupInterval: 200} });
const remindNonRespondent = (survey_id, users) => httpClient.post(`${END_POINT_SURVEYS}/${survey_id}/reminder`, users);
const suspendNonRespondent = (survey_id, users) => httpClient.post(`${END_POINT_SURVEYS}/${survey_id}/suspend`, users);

export {
    getSurvey,
    getSurveyMembers,
    getSurveyQuestion,
    getSurveys,
    submitAnswer,
    submitSurvey,

    getNonRespondent,
    getSurveysWithStatus,
    remindNonRespondent,
    suspendNonRespondent,
}
