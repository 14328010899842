import { defineStore } from 'pinia';
import { jwtDecode } from "jwt-decode";
import AuthService from '@/services/auth.service';
import { csrfCookieExists, csrfHandshake, getCookie, profiles } from '@/api/auth.api';
import Configuration from '@/services/configuration';
import { sleep } from '@/utils/globalFunctions';


export function isTokenValid(token) {
    if (!token) return false;

    try {
        const decoded = jwtDecode(token);
        const currentTime = Date.now() / 1000;  // convert milliseconds to seconds
        window.l("in isTokenValid, decoded.exp:", decoded?.exp);
        window.l("in isTokenValid, decoded.exp > currentTime - 60:", decoded?.exp > currentTime - 60);

        return decoded.exp > currentTime - 60;
    } catch (error) {
        window.le("Failed to decode JWT:", error);
        return false;
    }
}

// You can name the return value of `defineStore()` anything you want,
// but it's best to use the name of the store and surround it with `use`
// and `Store` (e.g. `useUserStore`, `useCartStore`, `useProductStore`)
// the first argument is a unique id of the store across your application
export const useAuthStore = defineStore('auth', {
    state: () => {
        const user = JSON.parse(localStorage.getItem('user'));
        const isRequestingCSRF = sessionStorage.getItem('requestingCSRF') === 'true';

        return {
            status: { 
                loggedIn: user && isTokenValid(user.accessToken) 
            },
            user: user && isTokenValid(user.accessToken) ? user : null,
            memberships: [],
            currentUserName: "",
            showGreeting: false,
            requestingCSRF: isRequestingCSRF,
        }
    },

    getters: {
        companyId() {
            return this.user ? this.user.companyId : null;
        },
        
        userRoles() {
            return this.user ? this.user.authorities?.map(item => item.authority) : [];
        },

        isRoot() {
            return this.user ? this.userRoles.includes("ROLE_ROOT") : false;
        },

        isCompanyAdmin() {
            return this.user ? this.userRoles.includes("ROLE_SUPERADMIN") : false;
        },

        isTeamAdmin() {
            return this.user ? this.userRoles.includes("ROLE_ADMIN") : false;
        },

        isMember() {
            return this.user ? this.userRoles.includes("ROLE_MEMBER") : false;
        },

        isMemberOnly() {
            if( !this.user ) return false;

            return this.isMember && !(this.isTeamAdmin || this.isCompanyAdmin);
        },
        
        isAdmin() {
            return this.user ? this.isRoot || this.isCompanyAdmin || this.isTeamAdmin : false;
        },

        isImpersonating() {
            l("isImpersonating", this.user);
            return this.user?.isImpersonator || false;
        },

        hasMultipleMemberships() {
            return this.memberships.length > 1;
        },

        maxRole() {
            let maxRole = "member";
            
            if (this.isTeamAdmin) {
                maxRole = "teamAdmin";
            }

            if (this.isCompanyAdmin) {
                maxRole = "companyAdmin";
            }

            return maxRole;
        },

        oauth2Url() {
            return Configuration.value("authUrl") + "/oauth2/login?provider=google";
        },

        oauth2UrlAzure() {
            return Configuration.value("authUrl") + "/oauth2/login";
        }
    },

    actions: {
        hasPermission: (permission) => {
            return this.user ? this.userRoles.includes(permission) : false;
        },

        updateUser(params) {
            if( !this.user ) return;

            this.user = { ...this.user, ...params };
            localStorage.setItem('user', JSON.stringify(this.user));
            return this.user;
        },

        loginSuccess(user) {
            this.status.loggedIn = isTokenValid(user.accessToken)
            this.user = user;
            this.showGreeting = true;
            this.currentUserName = this.user.firstName;
        },

        loginFailure(error) {
            window.le("Failed to (re)login:", error);
            this.status.loggedIn = false;
            this.user = null;
            this.showGreeting = false;
            this.currentUserName = "";
        },

        async logout() {
            await AuthService.logout();
            this.status.loggedIn = false;
            this.user = null;
            this.showGreeting = false;
            this.currentUserName = "";
        },

        // set showInto false and persists to local storage as well (otherwise intro will be prompted on page refresh)
        setIntroShown() {
            console.log("setting local showIntro: false")
            this.updateUser({ showIntro: false }); 
        },

        setGDPRAccepted() {
            console.log("setting local gdprAccepted: true")
            this.updateUser({ gdprAccepted: true });
        },
        
        updateName(user) {
            this.user.firstName = user.firstName;
            this.user.lastName = user.lastName
        },

        updateEmail(email) {
            this.user.email = email;
        },

        updateLanguage(language) {
            this.user.language = language;
        },

        isCSRFCheckInProgress() {
            const flagSet = this.requestingCSRF && sessionStorage.getItem('requestingCSRF') === 'true';

            return flagSet && csrfCookieExists() === false;
        },

        async login(user) {
            try {
                return await AuthService.login(user);
            } catch (error) {
                this.loginFailure(error);
                return await Promise.reject(error);
            }
        },

        async urlLogin(token) {

            try {
                if( this.isCSRFCheckInProgress() ) {
                    window.l("[AuthStore] CSRF handshake is in progress, delaying urlLogin call.");
                    await sleep(500);
                } else {
                    this.requestingCSRF = false;
                    sessionStorage.removeItem('requestingCSRF');
                }

                return await AuthService.urlLogin(token);
            } catch (error) {
                this.loginFailure(error);
                return await Promise.reject(error);
            }
        },

        relogin() {
            return AuthService.relogin().then(
                user => {
                    this.loginSuccess(user);    
                    return Promise.resolve(user);
                },
                error => {
                    this.loginFailure(error);
                    return AuthService.logout();
                }
            );
        },

        tokenLogin() {
            return AuthService.loginToken().then(
                user => {
                    this.loginSuccess(user);
                    return Promise.resolve(user);
                },
                error => {
                    this.loginFailure(error);   
                    return Promise.reject(error);
                }
            );
        },

        stopImpersonation() {
            return AuthService.stopImpersonation();
        },

        loadProfiles(){

            return profiles().then(
                (response) => {
                    l("in authStore.loadProfiles, response:", response);
                    this.currentUserName = response.data.firstName;
                    this.showGreeting = true;
    
                    // Check if there is only one membership
                    if (response.data.memberships) {
                        l("in loadProfiles, response.data.memberships:", response.data.memberships);
                        if (response.data.memberships.length > 1) {
                            // Store them in the value
                            this.memberships = response.data.memberships;
                            return;
                        }
                    }
                }
    
            ).catch(
                (error) => {
                    le("in loadProfiles, error:", error);
                }
            )
        },

        getNextPathForUser(target) {
            let targetSuffix = target ? `?target=${target}` : '';

            if (!this.user) {
                return `/login${targetSuffix}`;
            }


            if (this.user.showIntro) {
                if (target && target.includes("intro")) {
                    targetSuffix = '';
                }

                return `/intro${targetSuffix}`;
            }

            if (!this.user.gdprAccepted) {
                if (target && target.includes("gdpr")) {
                    targetSuffix = '';
                }

                return `/gdpr${targetSuffix}`;
            }

            if (sessionStorage.getItem('hasActiveSurvey') === 'true') {
                sessionStorage.removeItem('hasActiveSurvey');

                if (target && target.includes("/survey/start")) {
                    targetSuffix = '';
                }

                return `/survey/start${targetSuffix}`;
            }

            return target || '/tb';
        }
    }
});