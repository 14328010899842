export default class EventHandler {
    constructor() {
      this.events = {};
    }
  
    on(event, handler) {
      if (!this.events[event]) {
        this.events[event] = [];
      }
      this.events[event].push(handler);
    }
  
    off(event, handler) {
      if (!this.events[event]) return;
  
      if (!handler) {
        delete this.events[event];
      } else {
        this.events[event] = this.events[event].filter(h => h !== handler);
      }
    }
  
    emit(event, ...args) {
      if (!this.events[event]) return;
  
      this.events[event].forEach(handler => handler(...args));
    }
  }