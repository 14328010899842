import Configuration from "@/services/configuration";
import {
    forgotPassword,
    logout,
    resetPassword,
    resetPasswordAuthentication,
    signin,
    token,
    urlLogin,
    stopImpersonation
} from "../api/auth.api";
import { cachedHttpClient } from '@/api/httpClient';
import router from "@/router/index.js";
import { routes } from "@/router/index.js";
import { useAppStore } from "@/store/app.store";

class AuthService {
    
    login(user) {
        l("in login");

        return signin(user);
    }

    urlLogin(token) {
        l("in urlLogin");

        return urlLogin(token);
    }

    forgotPasswordLink(email) {
        return forgotPassword(email);
    }

    resetPasswordValidity(token) {
        return resetPasswordAuthentication(token);
    }

    updatePassword(request) {
        return resetPassword(request);
    }

    loginToken() {
        l("in loginToken");
        return token().then(this.handleLoginResponse.bind(this));
    }

    relogin() {
        l("in relogin");

        return this.loginToken();
    }

    logout() {
        l("in logout");
        //show loading wheel while logging out
        const appStore = useAppStore();
        appStore.setLogoutMode(true);

        this.userCleanUp();

        return logout()
            .then(response => {
                l('in logout().then:', response);

                return response;
            })
            .catch(error => {
                l('in logout().catch:', error);

                return Promise.reject(error);
            })
            .finally(() => {
                const loginUrl = Configuration.value("loginUrl")
                    ?? routes.find(route => route.name?.toLowerCase() === 'login')?.path
                    ?? '/login';

                try {
                    l("in logout, before navigating to:", loginUrl);

                    router
                        .push({ path: loginUrl.replace(/^\/#/, '') })
                        .then(() => {
                            l("in logout, after call to navigating to:", loginUrl, 'before `router.go(0)`');

                            return router.go(0);
                        })
                        .catch(ex => { le('in auth.service logout() router.push() ...', ex); throw ex; });
                } catch (ex) {
                    le('in auth.service logout()', ex);
                    window.location = loginUrl + '?rl';
                    setTimeout(()=> { window.location = ("" + window.location).replace('?rl', ''); window.location.reload(); }, 100);
                }
            });
    }

    userCleanUp() {
        l("in userCleanUp");

        // Invalid cookie
        localStorage.removeItem('user');
        l("in logout, user removed from localstorage!");
        // clean axios HTTP cache
        cachedHttpClient?.storage?.cleanup();
    }

    stopImpersonation() {
        l("in stopImpersonation");
        this.userCleanUp();
        stopImpersonation().then(() => {
            // Navigate to /login-success
            router.push({ path: '/login-success' }).then(() => router.go(0));
        });
    }

    handleLoginResponse(response) {
        const appStore = useAppStore();

        // Make sure to cleanup any previous user data
        this.userCleanUp();
        if (response?.data?.accessToken) {
            localStorage.setItem('user', JSON.stringify(response.data));

            appStore.emit('localStorage:setItem:user', true);
            appStore.off('localStorage:setItem:user');

            return Promise.resolve(response.data);
        }
        return Promise.reject('Access Token not found')
    }

}

export default new AuthService();
