<template>
    <div id="app">
        <v-app>
            <v-main>
                <v-container class="noPaddingMobile">
                    <!-- TODO: Vue 3: END -->
                    <router-view v-slot="{ Component }">
                        <v-slide-x-reverse-transition :hide-on-leave="true">
                            <component :is="Component" />
                        </v-slide-x-reverse-transition>
                    </router-view>
                </v-container>
            </v-main>

        </v-app>
    </div>
</template>

<script lang="ts">
    import { defineComponent, onMounted } from "vue";
    import { useRoute } from "vue-router";
    import {csrfHandshake} from "@/api/auth.api";

    export default defineComponent({

        setup() {
            const route = useRoute();

            onMounted(async () => {
                window.l('[App] in mounted');

                try {
                    window.l('Starting CSRF handshake...');
                    sessionStorage.setItem("requestingCSRF", "true");
                    await csrfHandshake();
                    window.l('CSRF handshake completed successfully');
                    sessionStorage.removeItem("requestingCSRF");
                } catch (error) {
                    window.l('CSRF handshake failed:', error);
                }
            });

            return {
                route
            }
        }
    });
</script>
<style>

    .impersonation-banner {
        position: fixed;
        top: 0;
        left: 50%;
        transform: translateX(-50%);
        width: auto;
        max-width: 80%;
        /* Not full width */
        background-color: yellow;
        color: black;
        text-align: center;
        padding: 10px 20px;
        border-radius: 4px;
        z-index: 5000;
        /* Ensure it's above other elements */
        box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
        /* Add some shadow to lift the banner */
    }

    .bg-transparent {
        background-color: transparent !important;
    }

    .hide-for-preload {
        pointer-events: none;
        height: 1px;
        left: 0;
        opacity: 1%;
        overflow: hidden;
        position: absolute;
        top: 0;
        width: 1px;
    }

    .v-select__selections input[readonly] {
        cursor: pointer;
    }
</style>
