<template>
    <div class="fade-in">
        <!-- v-stepper components steps 1 2 3 4 keeping track of survey questions -->
        <p
       v-show="$vuetify.display.smAndUp"
            class="text-center fade-in text-grey"

            v-if="surveyDetails.teamName"
        >
            Team {{ surveyDetails.teamName }} {{ $t("discussionMode.survey") }}
            {{ getFormattedDate(surveyDetails.startedAt)}} -
            {{ getFormattedDate(surveyDetails.endedAt)}}
        </p>


<!-- simplified question tracker when small screen or more than 14 questions -->
        <div
            class="text-center text-primary text-medium"
            v-show="$vuetify.display.xs || questionCollection.length>14"
        >
            {{ $t("discussionMode.question") }}
            {{ e1 }}/{{ questionCollection.length }}
        </div>
        <!-- TODO:V3-end -->
        <v-stepper
            v-model="e1"
            :key="!!questionCollection.length"
            editable
            alt-labels
            class="elevation-0 px-0 px-sm-2 dmStepper"
        >
                <v-row justify="center">
                    <v-col cols="12" lg="8" xl="6"  :class="{ 'pt-0': $vuetify.display.xs }">
                        <v-stepper-header v-show="$vuetify.display.smAndUp &&questionCollection.length<15">
                            <template
                                v-for="(question, i) in questionCollection"
                                :key="question.id"
                            >
                                <v-stepper-item
                                    :complete="isDone(i)"
                                    :value="i + 1"
                                    class="mx-auto"
                                ></v-stepper-item>

                                <v-divider
                                    v-if="i !== questionCollection.length - 1"

                                ></v-divider>
                            </template>
                        </v-stepper-header>
                    </v-col>
                </v-row>

                <v-stepper-window>
                    <stepper-content
                        @back="prevStep"
                        @next="nextStep"
                        :question="question"
                        v-for="(question, j) in questionCollection"
                        :key="question.id"
                        :step="j"
                        :nextLabel="nextLabel()"
                        :assignees="assignees"
                        :is-adhoc="surveyDetails.isAdhoc"
                        :finished="setDone"
                    />
                </v-stepper-window>

        </v-stepper>
    </div>
</template>

<script>
import { getAllQuestions, getDiscussion } from "../api/discussion.api";
import StepperContent from "@/components/StepperContent.vue";
import moment from "moment";
import globalFunctions from "@/utils/globalFunctions";
export default {
    components: {
        StepperContent
    },
    name: "ProgressTracker",
    data: () => ({
        e1: 1,
        done: [],
        questionCollection: [],
        surveyDetails: {},
        assignees: []
    }),
    mounted() {
        getDiscussion(this.$route.params.discussion_id).then(response => {
            let survey = response.data;
            this.surveyDetails = {
                teamName: survey.teamName,
                startedAt: survey.startedAt,
                endedAt: survey.endedAt,
                isAdhoc: survey.adhoc
            };

            const deduplicate = (arr) => {
                return Array.from(new Set(arr.map(item => item.id)))
                    .map(id => arr.find(item => item.id === id));
            };

            // Deduplicate team members and admins to get unique assignees
            this.assignees = deduplicate(survey.teamMembers.concat(survey.admins));
        });

        getAllQuestions(this.$route.params.discussion_id).then(response => {
            this.questionCollection = response.data.sort((a, b) => {
                return a.order - b.order;
            });

            //to track when all FFs are discussed
            this.done = Array.from({ length: this.questionCollection.length });

            //to track questions that got no FFs at all
            this.empty = Array.from({ length: this.questionCollection.length });
        });


    },
    watch: {
        //listens to step changes in v-stepper component
        steps(val) {
            if (this.e1 > val) {
                this.e1 = val;
            }
        }
    },
    computed: {
    },
    methods: {
        isDone(stepIndex) {
            return this.done[stepIndex] === true;
        },
        setDone(index, status) {
            this.done[index] = status; //TODO V3-end
        },
        nextStep() {
            if (this.e1 < this.questionCollection.length) {
                this.e1 += 1;
            } else if (this.e1 === this.questionCollection.length) {
                globalFunctions.piwikAnalytics(['trackEvent','Discussion actions', 'Completed']);
                this.$router.push({
                    name: "completion"
                });
            }
        },
        prevStep() {
            if (this.e1 > 1) {
                this.e1 -= 1;
            } else if (this.e1 === 1) {
                this.$router.back()
              /*   this.$router.push({
                    name: "select"
                }); */
            }
        },
        nextLabel() {
            if (this.e1 === this.questionCollection.length) {
                return this.$t("buttons.finish");
            } else {
                return this.$t("buttons.next");
            }
        },
        getFormattedDate(date){
            return moment(date).format("ddd DD MMM YYYY")
        }
    }
};
</script>

<style >


.questionTopic {
    display: none;
    font-size: 0.8rem;
    overflow-wrap: break-word !important;
    text-align: center !important;
}

.v-stepper {
    background: none !important;
}



.v-stepper-header {
    box-shadow: none !important;
}

.dmStepper .v-stepper-item .v-ripple__container {
    display: none !important;
}
/*  TOD0 Vuetify END: flex causing issue of edit step so update that */
.dmStepper .v-stepper-item {
    flex: auto !important
}


.dmStepper .v-stepper-item {
    transition: all 0.25s ease-in-out;
}

.dmStepper .v-stepper-item .v-icon {
    color: white !important;
}

.v-application .dmStepper .v-stepper-item--selected .v-stepper-item__avatar {
    transform: scale(1.5);
    background: rgb(184, 133, 197) !important;
}

.dmStepper .v-stepper-item {
    flex-basis: auto !important;
}
.dmStepper.v-stepper--alt-labels .v-stepper-header .v-divider {
    margin: 35px -14px;
}
</style>
