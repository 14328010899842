import { defineStore } from 'pinia';
import { getEscalateAdmins } from '@/api/admin.api';
import i18n from "../i18n";

import { useAuthStore } from './auth.store';
import { useEntitiesStore } from './entities.store';
import { useQuestionEditorStore } from './questionEditor.store';
import { useAdhocStore } from './adhoc.store';
import { setLanguageLocale } from '@/utils/languageHelpers';
import { settings } from '@/api/settings.api';
import globalFunctions from "@/utils/globalFunctions";
import EventHandler from '@/utils/eventHandler';
import { getGenericLocale } from '@/utils/languageHelpers';
import { useI18n } from 'vue-i18n';

// You can name the return value of `defineStore()` anything you want,
// but it's best to use the name of the store and surround it with `use`
// and `Store` (e.g. `useUserStore`, `useCartStore`, `useProductStore`)
// the first argument is a unique id of the store across your application
export const useAppStore = defineStore('app', {
    state: () => {
        const { t, locale } = useI18n();
        
        return {
            anonym: false, //default anonym answer mode for a survey fill out (public by default)
            loggingOut: false, //styling variable to hide rest of the UI after user clicks "logout"
            navDrawer: true,
            supportedLangs: [],
            escalateAdmins: null,
            userSettings: null,
            topGrade: null,
            contactLinks: {
                'de-CH': "https://joineer.com/ueber-uns/#kontaktdaten",
                'en-GB': "https://joineer.com/kontakt-en/#kontaktdaten",
                fr: "https://joineer.com/kontakt-fr/#kontaktdaten",
                it: "https://joineer.com/kontakt-it/#kontaktdaten"
            },
            events: {},
            locale: locale,
            eventHandler: new EventHandler(),
        }
    },

    getters: {
        surveyRunning() {
            return this.userSettings?.pulseSurveyRunning || false;
        },

        featureFlags() {
            return this.userSettings?.featureFlags || null;
        },

        mandatoryLang() {
            return this.userSettings?.languages[0] || null;
        },

        contactLink() {
            return this.contactLinks.hasOwnProperty(this.locale)
                ? this.contactLinks[this.locale]
                : this.contactLinks['en-GB'];
        },

        lang() {
            return getGenericLocale(this.locale || this.mandatoryLang || 'de');
        }
    },

    actions: {
          
        showNavDrawer(newVal) {
            this.navDrawer = newVal;
        },
        
        toggleNavDrawer() {
            this.navDrawer = !this.navDrawer;
        },

        RESET() {
            this.$reset();
        },

        checkFeature(feature) {
            return this.userSettings?.featureFlags.includes(feature) || null;
        },

        // when user changes the default anonymity toggle in the survey question
        changeDefaultAnonym(mode) {
            console.log("setting survey question anonym mode to: " + mode)
            this.anonym = mode;
        },

        setLogoutMode(mode) {
            this.loggingOut = mode;
        },

        // for escalate task/FF autocomplete
        fetchEscalateAdmins() {
            getEscalateAdmins().then(response => {
                this.escalateAdmins = response.data;
            });
        },

        reset() {
            const authStore = useAuthStore();
            const entitiesStore = useEntitiesStore();
            const questionEditorStore = useQuestionEditorStore();
            const adhocStore = useAdhocStore();

            this.$reset();
            entitiesStore.RESET_ENTITY_EXPAND();
            questionEditorStore.RESET();
            adhocStore.RESET_ALL();

            // authStore.logout();
        },

        changeUserLang(lang) {
            if (!this.userSettings) {
                this.userSettings = {}; // Instantiate userSettings if it doesn't exist
            }
            this.userSettings.userLanguage = lang;
        },

        setEventHandler(eventName, eventHandler) {
            if (this.events[eventName]) {
                lw(`store, reassigning event handler for ${eventName} from ${this.events[eventName]} to ${eventHandler}`);
            }

            if (!eventHandler) {
                delete this.events[eventName];
            } else {
                Object.assign(this.events[eventName], eventHandler);
            }
        },

        on(eventName, eventHandler) {
            window.l(`appStore, adding handler for event ${eventName}`);
            return this.eventHandler.on(eventName, eventHandler);
        },

        off(eventName, eventHandler) {
            window.l(`appStore, removing handler for event ${eventName}`);
            return this.eventHandler.off(eventName, eventHandler);
        },

        emit(event, ...args) {
            window.l(`appStore, emitting event ${event} with args: ${args}`);
            return this.eventHandler.emit(event, ...args);
        },

        setRole(role) {
            this.userRole = role;
        },

        async setSettings(user) {
            l("[AppStore]: setSettings");

            const authStore = useAuthStore();
            const questionEditorStore = useQuestionEditorStore();   
    
            // set settings in store
            const response = await settings(user?.companyId, user?.userId)
            l("[AppStore]: setSettings.then");
    
            this.storeSettings(response.data);
            questionEditorStore.setQuestionIcons(user.companyId);
    
            const roles = authStore.userRoles;
            console.log("user roles: " + roles);
            let maxRole = "member";
            if (roles.includes("ROLE_ADMIN")) {
                maxRole = "teamAdmin";
            }
            if (roles.includes("ROLE_SUPERADMIN")) {
                maxRole = "companyAdmin";
            }
            //  log some important stats when running locally on dev mode
            if ($devMode) {
                console.group("Main details");
                    console.log("%cCOMPANY ID", "font-weight: bold", user.companyId);
                    console.log("%cUSER ID", "font-weight: bold", user ? user.userId : "NOT FOUND");
                    console.log("%cSETTINGS", "font-weight: bold", s(this.userSettings) );
                console.groupEnd();
            }
    
            if ($devMode) {
                //staging PIWIK
                globalFunctions.piwikAnalytics(['setCustomDimensionValue', 1, user.companyId]);
            } else {
                // prod PIWIK
                globalFunctions.piwikAnalytics(['setCustomDimensionValue', 4, user.companyId]);
            }
    
            // set language locale from languageHelpers
            setLanguageLocale(this.userSettings?.userLanguage);
        },

        storeSettings(settings) {
            this.userSettings = settings;
            this.supportedLangs = settings.languages;
            this.topGrade = settings.topGrade;

            if (settings.anonymDefault) {
                this.anonym = true
            }
        },
    }
});