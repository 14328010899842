<template>
    <div id="auth-layout">
        <!-- TODO: Vue 3: END -->
        <router-view v-slot="{ Component }">
            <v-slide-x-reverse-transition :hide-on-leave="true">
                <component :is="Component" />
            </v-slide-x-reverse-transition>
        </router-view>
    </div>
</template>

<script lang="ts">
import { getSpecificLocale } from '@/utils/languageHelpers';
import { defineComponent, onMounted, watch } from "vue";
import { useRoute } from "vue-router";
import { useI18n } from "vue-i18n";
import { useLocale } from 'vuetify'
import { useAuthStore } from "@/store/auth.store";

export default defineComponent({
    components: {

    },
    setup() {
        const route = useRoute();
        const { t, locale, te } = useI18n();
        const { current: currentVuetifyLocale } = useLocale();
        const authStore = useAuthStore();

        onMounted(() => {
            window.l('[AuthLayout] in mounted');
            window.l("route", route)

            window.logout = () => { authStore.logout(); }

            if (route.meta?.hasOwnProperty("title")) {
                setDocumentTitle(route.meta.title);
            }
        });

        const setDocumentTitle = (name): void => {
            //translate the text in browser tab if possible
            document.title = te(name) ? t(name) as string : name;
        }

        watch(
            locale,
            (newVal, oldVal) => {
                if (!newVal) return;

                const specificLocale = getSpecificLocale(newVal);
                document.documentElement.setAttribute("lang", specificLocale);
                setDocumentTitle(route.meta?.title);

                if (currentVuetifyLocale.value !== specificLocale) {
                    currentVuetifyLocale.value = specificLocale;
                }
            },
            { immediate: true }
        );

        watch(route, (to, from) => {
            if (to.meta.hasOwnProperty("title")) {
                setDocumentTitle(to.meta.title);
            }
        });

        return {
           
        };
    }
});
</script>

<style lang="css" scoped>
#auth-layout {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100vh;
}
</style>