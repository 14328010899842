<template>
    <div id="app-layout">
        <div v-if="isImpersonating" class="impersonation-banner">
            You are currently impersonating another user.
            <v-btn @click="stopImpersonation" color="red" variant="outlined">
                Stop Impersonating
            </v-btn>
        </div>

        <Snackbar v-if="showSnack" />

        <div class="pa-12 mx-auto text-center" v-if="logoutMode">
            <v-progress-circular
                indeterminate
                size="30"
                color="primary"
                class="mx-auto mt-10"
            ></v-progress-circular>
            <div class="text-caption mt-7">{{ $t("loggingOut") }}</div>
        </div>

        <Navbar class="d-print-none" v-if="!logoutMode && topNav" />

        <section>
            <v-container class="noPaddingMobile" v-if="!logoutMode">
                <!-- TODO: Vue 3: END -->
                <router-view v-slot="{ Component }">
                    <v-slide-x-reverse-transition :hide-on-leave="true">
                        <component :is="Component" />
                    </v-slide-x-reverse-transition>
                </router-view>
            </v-container>
        </section>

        <!--Vuetify 3 Migration: Replaced fab with icon as fab has been discontinued-->
        <v-fab
            v-show="
                fab &&
                route.name !== 'login' &&
                route.name !== 'select' &&
                route.name !== 'question' &&
                !logoutMode
            "
            class="d-print-none"
            elevation="8"
            icon="mdi-chevron-up"
            app
            v-scroll="onScroll"
            @click="toTop"
        />

        <NavbarBottom class="d-print-none" v-if="!topNav && !logoutMode" />

    </div>
</template>

<script lang="ts">
import { settings } from "@/api/settings.api.js";
import Navbar from "@/components/Navbar.vue";
import NavbarBottom from "@/components/NavbarBottom.vue";
import Snackbar from "@/components/Snackbar.vue";
import { routes } from "@/router/index.js";
import { getSpecificLocale } from '@/utils/languageHelpers';
import { defineComponent, computed, onMounted, ref, watch } from "vue";
import { useRoute } from "vue-router";
import { useI18n } from "vue-i18n";
import globalFunctions, { UserSettings } from "@/utils/globalFunctions";
import { useLocale } from 'vuetify'
import { setLanguageLocale } from "@/utils/languageHelpers";
import { useQuestionEditorStore } from "@/store/questionEditor.store";
import { useSnackbarStore } from "@/store/snackbar.store";
import { useAuthStore } from "@/store/auth.store";
import { useAppStore } from "@/store/app.store";

export default defineComponent({
    components: {
        Navbar,
        NavbarBottom,
        Snackbar,
    },
    setup() {
        const route = useRoute();
        const { t, locale, te } = useI18n();
        const { current: currentVuetifyLocale } = useLocale();
        const questionEditorStore = useQuestionEditorStore();
        const snackbarStore = useSnackbarStore();
        const authStore = useAuthStore();
        const appStore = useAppStore();

        const loggedIn = computed(() => {
            window.l("in get loggedIn:", authStore.status.loggedIn);
            return authStore.status.loggedIn;
        });

        const user = computed(() => authStore.user);

        onMounted(() => {
            window.l('[AdminLayout] in mounted');

            window.logout = () => { authStore.logout(); }

            if (route.meta?.hasOwnProperty("title")) {
                setDocumentTitle(route.meta.title);
            }
        });

        const fab = ref<boolean>(false);
        const onScroll = (e): void => {
            if (typeof window === "undefined") return;
            const top = window.pageYOffset || e.target.scrollTop || 0;
            fab.value = top > 150;
        }

        const toTop = (): void => {
            window.scrollTo({ top: 0, behavior: 'smooth' });
        }

        const setDocumentTitle = (name): void => {
            //translate the text in browser tab if possible
            document.title = te(name) ? t(name) as string : name;
        }

        const setSettings = (): Promise<any> => {
            window.l("[App.vue]: in setSettings");

            // set settings in appStore
            return settings(user.value?.companyId, user.value?.userId).then((response) => {
                window.l("[App.vue]: in settings().then");

                const storeSetSettingsPromise = appStore.setSettings(response.data);
                window.l('appStore.setSettings(response.data)', storeSetSettingsPromise);

                let userSettings = response.data;
                if (user.value) {
                    questionEditorStore.setQuestionIcons(user.value.companyId); 
                }

                const roles = authStore.userRoles;
                console.log("user roles: " + roles);

                let maxRole = "member";
                if (roles.includes("ROLE_ADMIN")) {
                    maxRole = "teamAdmin";
                }

                if (roles.includes("ROLE_SUPERADMIN")) {
                    maxRole = "companyAdmin";
                }
                //  log some important stats when running locally on dev mode
                if (window.$devMode) {
                    console.group("Main details");
                    console.log(
                        "%cCOMPANY ID",
                        "font-weight: bold",
                        user.value ? user.value.companyId : "NOT FOUND"
                    );

                    console.log(
                        "%cUSER ID",
                        "font-weight: bold",
                        user.value ? user.value.userId : "NOT FOUND"
                    );

                    console.log(
                        "%cSETTINGS",
                        "font-weight: bold",
                        JSON.stringify(userSettings)
                    );
                    console.groupEnd();
                }

                if (window.$devMode) {
                    //staging PIWIK
                    globalFunctions.piwikAnalytics(['setCustomDimensionValue', 1,  user.value?.companyId || 'not logged in']);
                } else {
                    // prod PIWIK
                    globalFunctions.piwikAnalytics(['setCustomDimensionValue', 4, user.value?.companyId || 'not logged in']);
                }

                if( appStore.userSettings ) {
                    setLanguageLocale((appStore.userSettings as UserSettings)?.userLanguage);
                } else {
                    setLanguageLocale();
                }

                return storeSetSettingsPromise;
            });
        }

        const showSnack = computed((): boolean => {
            return snackbarStore.show;
        })

        const logoutMode = computed((): boolean => {
            return appStore.loggingOut;
        });

        window.l("[App.vue]: in created, user, loggedIn:", user.value, loggedIn.value);

        const loginRoute = routes.find(route => route.name?.toLowerCase() === 'login');
        const isOnLoginUrl = loginRoute && location.hash.replace(/^#/, '').indexOf(loginRoute.path) === 0;

        if (user.value && loggedIn && !isOnLoginUrl) {
            setSettings();
        }

        const topNav = computed(() => {
            //  hide top navbar & drawer menu in certain pages
            const hiddenPages = [
                "login",
                "login-success",
                "start",
                "question",
                "end",
                "intro",
                "gdpr",
                "error",
                "feature-error",
                "reset-password",
                "tb",
                "2fa"
            ];
            return !hiddenPages.includes(route.name as string);
        });

        const isImpersonating = computed((): boolean => {
            window.l('isImpersonating:', authStore.isImpersonating);
            return authStore.isImpersonating;
        });

        const stopImpersonation = (): void => {
            window.l('in stopImpersonation');
            authStore.stopImpersonation();
        };

        watch(
            locale,
            (newVal, oldVal) => {
                if (!newVal) return;

                const specificLocale = getSpecificLocale(newVal);
                document.documentElement.setAttribute("lang", specificLocale);
                setDocumentTitle(route.meta?.title);

                if (currentVuetifyLocale.value !== specificLocale) {
                    currentVuetifyLocale.value = specificLocale;
                }
            },
            { immediate: true }
        );

        watch(loggedIn, (val, oldVal) => {
            window.l('in logInWatcher, val, oldVal:', val, oldVal);
        });

        watch(route, (to, from) => {
            if (to.meta.hasOwnProperty("title")) {
                setDocumentTitle(to.meta.title);
            }
        });

        return {
            fab,
            route,
            showSnack,
            loggedIn,
            user,
            logoutMode,
            topNav,
            onScroll,
            toTop,
            isImpersonating,
            stopImpersonation
        };
    }
});
</script>
